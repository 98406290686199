import { useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, CircularProgress } from '@mui/material';
import LeadsIcon from '../assets/svgs/leadsIcon';
import { KanbanView } from '../sections/kanban/view';
import { CheckCircle, Error } from '@mui/icons-material';
import { SnackbarProvider } from 'src/components/snackbar';
import AnalysisTable from 'src/sections/leads/analysisTable';

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 0px 88px",
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: 'calc(100vh - 0px)',
    overflow: 'hidden'
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const LeadsAnalysis = () => {
    return (
        <MainSection>
            <HeadingSection>
                <HeadingContentSection>
                    {/* <Box sx={{
                        widht: '16px',
                        height: '16px',
                        mt: "-10px",
                    }}>
                        <LeadsIcon />
                    </Box> */}
                    <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                        Leads Analysis
                    </Typography>
                </HeadingContentSection>

            </HeadingSection>

            <AnalysisTable />
        </MainSection>
    )
}

export default LeadsAnalysis