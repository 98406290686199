import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';

// @mui
import { Typography, Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import KanbanInputName from './kanban-input-name';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomModal from 'src/components/modal/customModal';
import { useBoolean } from 'src/hooks/use-boolean';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const PagesRowSection = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px'
}));

const ActionTitle = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 500,
    color: "#727374",
}));

const RouteModal = ({ openRouteModal, selectedTab, selectedType, updateTabRoute }) => {

    const [data, setData] = useState([]);

    useState(() => {
        setData(selectedTab?.[selectedType] || [])

    }, [selectedTab, selectedType])

    const actions = (
        <Button variant="contained" onClick={() => {
            openRouteModal.onFalse()
            updateTabRoute(selectedTab?.id, selectedType, data)
        }}>
            Save
        </Button>
    )

    const addNewRoute = () => {
        setData((prev) => {
            const updated = [...prev];
            updated.push("");
            return updated;
        })
    }

    const deleteRoute = (index) => {
        setData((prev) => {
            const updated = [...prev];
            updated.splice(index, 1);
            return updated;
        })
    }

    const updateRoute = (index, value) => {
        setData((prev) => {
            const updated = [...prev];
            updated[index] = value;
            return updated;

        })
    }

    const description = (
        <>
            {data.map((route, index) => {
                return (
                    <Box key={index}>
                        <KanbanInputName
                            placeholder="Route"
                            // value={tabs[id].title}
                            value={route}
                            action={actions}
                            onChange={(e) => updateRoute(index, e.target.value)}
                        />
                        <IconButton onClick={() => deleteRoute(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )

            })}
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <IconButton sx={{ width: "30px", height: "30px" }} onClick={addNewRoute} >
                    <AddCircleIcon />
                </IconButton>
            </Box>
        </>

    )

    return (
        <CustomModal open={openRouteModal.value} title="update Route" description={description} close={() => openRouteModal.onFalse()} action={actions} />
    )
}

const PagePermissions = ({ tabsIds, tabs, handleTabDelete, handleTabAdd, updateTabName, updatePageTitle, updateTabRoute, deletePage }) => {

    const openRouteModal = useBoolean();
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    return (
        <>

            <PagesRowSection>

                <Box
                    sx={{ display: "flex", width: "100%", alignItems: "center", gap: "10px" }}
                >

                    <KanbanInputName
                        placeholder="Permission Name"
                        value={tabsIds.title}
                        onChange={(e) => updatePageTitle(tabsIds, e.target.value)}
                    />
                    <Button
                        variant='contained'
                        color='error'
                        size="small"
                        onClick={() => deletePage(tabsIds?.id)}
                    >
                        Delete Page
                    </Button>
                </Box>

                <TableContainer component={Paper} sx={{ borderRadius: '0px' }}>
                    <Table sx={{ minWidth: 350, width: 590 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ maxWidth: 400, }}>Name</TableCell>
                                <TableCell align="center" style={{ maxWidth: 10, }}>Read</TableCell>
                                <TableCell align="center" style={{ maxWidth: 10, }}>Write</TableCell>
                                <TableCell align="center" style={{ maxWidth: 30, }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tabsIds?.child?.map((id) => (
                                tabs[id] && <TableRow
                                    key={id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <ActionTitle>
                                            {/* {tabs[id].title} */}
                                            <KanbanInputName
                                                placeholder="Permission Name"
                                                value={tabs[id].title}
                                                onChange={(e) => updateTabName(tabs[id], e.target.value)}
                                            />
                                        </ActionTitle>

                                    </TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            {/* <AntSwitch
                                                checked={tabs[id].r}
                                                onChange={(e) => handlePermissionChange(id, "r", e.target.checked)}
                                                // disabled={!tabs[id].r}
                                                inputProps={{ 'aria-label': 'ant design' }} /> */}
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                {/* <AntSwitch
                                                    checked={tabs[id].w}
                                                    onChange={(e) => handlePermissionChange(id, "w", e.target.checked)}
                                                    // disabled={!adminAccess[id].w}
                                                    inputProps={{ 'aria-label': 'ant design' }} /> */}
                                                {/* <TextField id="outlined-basic" label="path" variant="outlined" value={writeString} /> */}
                                                <IconButton onClick={() => {
                                                    setSelectedTab(id);
                                                    setSelectedType("r")
                                                    openRouteModal.onTrue()
                                                }}>
                                                    <AddCircleIcon />
                                                </IconButton>

                                            </Box>

                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            {/* <AntSwitch
                                                    checked={tabs[id].w}
                                                    onChange={(e) => handlePermissionChange(id, "w", e.target.checked)}
                                                    // disabled={!adminAccess[id].w}
                                                    inputProps={{ 'aria-label': 'ant design' }} /> */}
                                            {/* <TextField id="outlined-basic" label="path" variant="outlined" value={writeString} /> */}
                                            <IconButton onClick={() => {
                                                setSelectedTab(id);
                                                setSelectedType("w")
                                                openRouteModal.onTrue()
                                            }}>
                                                <AddCircleIcon />
                                            </IconButton>

                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            {/* <AntSwitch
                                                    //  checked={accessArray[id].r && accessArray[id].w}
                                                    onChange={(e) => handleBothChange(id, e.target.checked)}
                                                    // disabled={!adminAccess[id].r || !adminAccess[id].w}

                                                    inputProps={{ 'aria-label': 'ant design' }} /> */}
                                            <IconButton onClick={() => handleTabDelete(tabs[id])} >

                                                <DeleteIcon />
                                            </IconButton>

                                        </Box>
                                    </TableCell>

                                </TableRow>
                            )

                            )}
                        </TableBody>
                        {selectedTab && selectedType && openRouteModal.value && <RouteModal openRouteModal={openRouteModal} selectedTab={tabs?.[selectedTab] || {}} selectedType={selectedType} updateTabRoute={updateTabRoute} />}
                    </Table>
                    <Box
                        sx={{
                            width: 450,
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >

                        <IconButton onClick={() => handleTabAdd(tabsIds)}>
                            <AddCircleIcon />
                        </IconButton>
                    </Box>

                </TableContainer>

            </PagesRowSection >

        </>

    )
}

export default PagePermissions;