import * as devConfigFile from "./config.dev";
import * as localConfigFile from "./config.local";
import * as prodConfigFile from "./config.prod";
import * as betaConfigFile from "./config.beta";

const currentEnv = () => {
    return process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";
};

const ENVIRONMENT = currentEnv();
const fetchConfig = () => {
    if (ENVIRONMENT === "dev") return devConfigFile
    if (ENVIRONMENT === "prod") return prodConfigFile
    if (ENVIRONMENT === "beta") return betaConfigFile
    return localConfigFile;
};

const configFile = fetchConfig();

export {
    configFile,
};
