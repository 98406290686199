import { Box, Typography } from "@mui/material"

const Wave = () => {
    return (
        <>
            <Box sx={{
                padding: "64px 20px 0px 20px",
                height: '100vh',
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center"
            }}>
                <Typography variant="h6">Stand by for the big reveal – coming soon!</Typography>
            </Box>
        </>
    )
}
export default Wave;