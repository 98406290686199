import React from 'react';
import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';

// Card section container.
const CardSection = styled('div')(({ theme }) => ({
    padding: "16px",
    backgroundColor: theme.palette.grey[1000],
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const BlankCard = ({ children, sx = {} }) => (
    <CardSection sx={sx}>
        {children}
    </CardSection>
)

BlankCard.propTypes = {
    children: PropTypes.element.isRequired,
    sx: PropTypes.object,
}

export { BlankCard };
