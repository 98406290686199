import axios from 'axios';
import { getItem } from './cookies';
import { configFile } from '../config';
import { removeLoginSession } from '../utils/common';

const currentEnv = () => {
  return process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'local';
};
const ENVIRONMENT = currentEnv();
const { getApiUrl } = configFile;
const token = getItem('sessionToken');

const axiosIntance = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'session-token': token ?? '',
  },
});

axiosIntance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error?.response?.status;
    if (status === 713) {
      removeLoginSession();
      return window.location.href = '/';
    } else if (status === 700) {
      const { exp, sessionToken, token } = error.response.data;

      if (exp && sessionToken && token) {

        setLoginSession(exp, sessionToken, token);
      }

      window.location.href = "/profile?editPhone=true"

    }


    return Promise.reject(error);
  }
);

export default axiosIntance;
