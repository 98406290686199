import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = ({ children }) => {
    const valid = useAuth();
    if (!valid) {
        window.location.href = "/";
        return
    }
    return children;
};

export const PublicRoute = ({ children }) => {
    const valid = useAuth();
    if (valid) {
        return <Navigate to="/home" />;
    }
    return children;
};