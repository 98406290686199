import { jwtDecode } from "jwt-decode";
import { getItem } from "../lib/cookies";
import { useEffect } from "react";
import { removeLoginSession } from "../utils/common";

export const useSetPermission = (setPermission) => {
    useEffect(() => {
        try {
            const token = getItem("token")
            if (token) {
                const { access, tabPanel } = jwtDecode(token);
                setPermission({ access, tabPanel })
            }

        } catch (error) {
            if (window.location.pathname !== "/") {
                removeLoginSession()
                window.location.href = "/"
            }
            console.log(error);
        }
    }, [])
}