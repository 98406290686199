import React from 'react'

const AddUserSvg = () => (
    <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 470.72 466.93"
        width="30"
        height="30"
    >
        <defs>
            <style>{`
          .cls-1 {
            fill: #3573f0;
          }
    
          .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
            stroke-width: 0px;
          }
    
          .cls-2 {
            fill: #fcb98b;
          }
    
          .cls-3 {
            fill: #208a3c;
          }
    
          .cls-4 {
            fill: #27282e;
          }
    
          .cls-5 {
            fill: #fff;
          }
    
          .cls-6 {
            fill: #2e55a3;
          }
    
          .cls-7 {
            fill: #ffd3b3;
          }
    
          .cls-8 {
            isolation: isolate;
            opacity: .1;
          }`}
            </style>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <path class="cls-4" d="m239.04,252.74c-26.88,32.34-73.9,39.02-108.72,15.44-80.14,51.1-170.6-48.78-111.12-123.44,11.84-13.04,9.28-30.32,8.08-46.4,5.28-135.46,204.79-126.72,206.16,0-1.2,16.08-3.76,33.36,8,46.4,26.46,31.48,25.44,77.72-2.4,108Z" />
            <path class="cls-8" d="m199.11,112.24v27.51c-5.08,89.88-146.75,81.18-137.53-12.22,33.21,6.05,83.25-10.75,106.97-38.2l30.56,22.92Z" />
            <path class="cls-6" d="m249.92,299.62v27.92c-.01,6.3-4.24,11.8-10.32,13.44-70.2,18.41-143.95,18.44-214.16.08-6.17-1.59-10.48-7.15-10.48-13.52v-27.92c-.76-52.26,40.67-100.7,94-100.32h46.96c53.13-.42,94.82,48.01,94,100.32h0Z" />
            <path class="cls-1" d="m104.42,167.93v38.23c.88,13.47,12.45,23.71,25.93,22.94,13.48.77,25.06-9.47,25.93-22.94v-38.23h-51.86Z" />
            <path class="cls-2" d="m104.42,160.98v38.23c.88,13.47,12.45,23.71,25.93,22.94,13.48.77,25.06-9.47,25.93-22.94v-38.23h-51.86Z" />
            <path class="cls-8" d="m103.9,179.75h52.38v9.32h-52.38v-9.32Z" />
            <path class="cls-7" d="m199.11,98.35v27.51c-5.08,89.88-146.75,81.18-137.53-12.22,33.21,6.05,83.25-10.75,106.97-38.2l30.56,22.92Z" />
            <path class="cls-8" d="m61.58,113.63c39.76-1.15,77.6-17.34,105.88-45.32,0,0,29.61,19.29,31.65,30.04l-30.56-22.92c-28.02,28.29-67.37,42.34-106.97,38.2Z" />
            <path class="cls-8" d="m239.04,252.74c11.81,20.77,11.36,50.81,10.88,74.8-.01,6.3-4.24,11.8-10.32,13.44-29.73,7.83-60.24,12.36-90.96,13.52,19.68-24.04,48.72-38.49,79.76-39.68v-22c-24.33-8.43-40.68-31.3-40.8-57.04v-13.44c-14.85-.02-26.87-12.08-26.85-26.94.02-10.52,6.16-20.06,15.73-24.42-2.86-17-3.21-34.33-1.04-51.44,2.64-17.2,22.48-34.32,40.24-36.24,3.36-.32,9.04-.88,16.08-1.36,4.99,20.76-6.37,45.17,9.68,62.8,26.46,31.48,25.44,77.72-2.4,108h0Z" />
            <path class="cls-1" d="m133.61,443.17c-.02,3.71,2.47,6.97,6.05,7.93,40.37,10.95,82.06,16.27,123.88,15.8,41.76-.44,83.32-5.75,123.84-15.82,3.61-.94,6.11-4.21,6.09-7.94v-24.02c-.83-58.56-48.97-105.37-107.53-104.56h-44.81c-58.56-.81-106.7,46-107.53,104.56v24.04Z" />
            <path class="cls-6" d="m236.38,330.85c0,3.09,1.73,5.93,4.49,7.33,14.12,7.54,31.07,7.57,45.22.08,2.81-1.41,4.6-4.27,4.63-7.42v-44.5h-54.34v44.51Z" />
            <path class="cls-2" d="m236.38,322.64c0,3.09,1.73,5.93,4.49,7.33,14.12,7.54,31.07,7.57,45.22.08,2.81-1.41,4.6-4.27,4.63-7.42v-36.29h-54.34v36.3Z" />
            <path class="cls-8" d="m236.57,291.23h54.15v5.07h-54.15v-5.07Z" />
            <path class="cls-7" d="m168.46,195.46c.07,14.93,12.23,26.97,27.16,26.89,0,0,0,0,.01,0v13.45c.18,33.59,27.54,60.68,61.13,60.51h13.58c33.59.17,60.95-26.92,61.13-60.51v-13.45c14.85,0,26.89-12.04,26.89-26.89,0-10.54-6.16-20.11-15.75-24.48-1.16,7.06-18.61-42.76-24.72-42.76h-108.68c-6.11,0-23.57,49.82-24.72,42.76-9.72,4.26-16,13.86-16.03,24.47h0Z" />
            <path class="cls-8" d="m263.55,177.13c-33.96,0-40.75-22.15-47.55-22.15-8.22,0-11.65,15.61-18.61,29.12-1.83,3.35-6.03,4.59-9.38,2.76-2.54-1.39-3.95-4.21-3.53-7.08-2.85-18.69-3.19-37.69-1.02-56.47,4.24-20.11,20.06-35.76,40.21-39.79,6.64-.68,23.25-2.36,39.87-2.36s33.23,1.68,39.87,2.36c20.15,4.03,35.97,19.68,40.21,39.79,2.17,18.78,1.83,37.78-1.02,56.47.14,7.56-9.09,11.44-12.91,4.32-6.96-13.51-10.38-29.12-18.61-29.12-6.79,0-13.58,22.15-47.55,22.15h0Z" />
            <path class="cls-4" d="m263.55,168.56c-33.96,0-40.75-20.17-47.55-20.17-8.22,0-11.65,14.22-18.61,26.53-2,3.29-6.28,4.33-9.56,2.34-2.18-1.32-3.46-3.73-3.34-6.28-2.85-17-3.19-34.33-1.02-51.43,2.58-17.21,22.41-34.29,40.21-36.24,6.64-.62,23.25-2.15,39.87-2.15s33.23,1.53,39.87,2.15c17.8,1.95,37.63,19.03,40.21,36.24,2.17,17.1,1.83,34.43-1.02,51.43.18,3.84-2.79,7.1-6.63,7.28-2.55.12-4.95-1.16-6.28-3.34-6.96-12.31-10.38-26.53-18.61-26.53-6.79,0-13.58,20.17-47.55,20.17h0Z" />
            <path class="cls-3" d="m470.72,386.9c0,44.18-35.82,80-80,80-106.12-4.21-106.09-155.8,0-160,44.18,0,80,35.82,80,80Z" />
            <path class="cls-5" d="m430.72,378.9h-32v-32c0-4.42-3.58-8-8-8s-8,3.58-8,8v32h-32c-4.42,0-8,3.58-8,8s3.58,8,8,8h32v32c0,4.42,3.58,8,8,8s8-3.58,8-8v-32h32c4.42-.09,7.93-3.74,7.84-8.16-.08-4.3-3.55-7.76-7.84-7.84Z" />
        </g>
    </svg>
)

export default AddUserSvg