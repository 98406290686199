//@mui
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import AttachmentIcon from '@mui/icons-material/Attachment';
import PropTypes from 'prop-types';

const FileUploader = ({ inputName, inputId, inputRef, setFile }) => {
    const handleChange = (file) => setFile(file)

    return (
        <Box sx={{
            display: 'flex',
            gap: '6px',
            flexDirection: 'column',
            width: '100%'
        }}>
            <Typography sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: "#898A8B"
            }}>{inputName}</Typography>

            <TextField
                id={inputId}
                variant="outlined"
                type="file"
                size="small"
                onChange={(e) => handleChange(e.target.files[0])}
                sx={{
                    color: '#898A8B',
                    backgroundColor: '#D0D0D1',
                    borderRadius: '6px',
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#B8B9BA"
                        },
                        '&:hover fieldset': {
                            borderColor: "#B8B9BA"
                        }
                    },
                    '& input::file-selector-button': {
                        display: 'none',
                    },
                }}
                inputRef={inputRef}
                InputProps={{
                    sx: {
                        color: "#898A8B",
                        border: "1px solid",
                        borderColor: '#B8B9BA',
                        borderRadius: "6px",

                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) =>
                                    inputRef.current?.dispatchEvent(
                                        new MouseEvent('click', {
                                            view: window,
                                            bubbles: true,
                                            cancelable: true,
                                            buttons: 1,
                                        }),
                                    )
                                }
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#C09936',
                                    border: 'none',
                                    "&:hover": {
                                        color: '#fff',
                                        backgroundColor: '#C09936',
                                        border: 'none',
                                    }
                                }}
                            >
                                Choose File
                            </Button>
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <AttachmentIcon sx={{ transform: 'rotate(-45deg)', color: "#898A8B" }} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}

FileUploader.propTypes = {
    inputName: PropTypes.string.isRequired,
    inputId: PropTypes.string.isRequired,
    setFile: PropTypes.func.isRequired
}
export default FileUploader;