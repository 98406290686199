import React from 'react'

const UsersListSvg = () => (
    <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 480 480"
        width="30"
        height="30"
    >
        <defs>
            <style> {`
                .cls-1 {
                    fill: #3573f0;
      }

                .cls-1, .cls-2, .cls-3, .cls-4 {
                    stroke - width: 0px;
      }

                .cls-2 {
                    fill: #fadfb2;
      }

                .cls-3 {
                    fill: #c18e59;
      }

                .cls-4 {
                    fill: #2e55a3;
      } `}
            </style>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <path class="cls-4" d="m271.48,95.07l-5.26-10.54c-6.4-12.79-5.82-27.96,1.54-40.22,5.94-9.91,15.72-16.92,27.02-19.37l13.78-10.4C321.14,5.11,336.45,0,352.18,0c10.39,0,20.66,2.23,30.12,6.54l22.33,10.17c10.44,4.75,19.19,12.56,25.09,22.4,9.38,15.63,10.66,34.82,3.44,51.56l-9.21,21.34.06,8h8c8.84,0,16,7.16,16,16s-7.16,16-16,16h-8.44c-2.72,24.34-17.62,45.63-39.56,56.51v31.49l76.3,31.79c11.93,4.97,19.7,16.62,19.7,29.54v106.66H0v-106.66c0-12.92,7.77-24.58,19.7-29.54l76.3-31.79v-31.49l-.22-.1c-21.82-10.92-36.63-32.15-39.34-56.41h-8.44c-8.84,0-16-7.16-16-16s7.16-16,16-16h8l-.06-8-13.73-27.46c-6.4-12.79-5.82-27.96,1.54-40.22,5.94-9.91,15.72-16.92,27.02-19.37l13.78-10.4C97.14,5.11,112.45,0,128.18,0c10.39,0,20.66,2.23,30.12,6.54l22.33,10.17c10.44,4.75,19.19,12.56,25.09,22.4,9.38,15.63,10.66,34.82,3.44,51.56l-2.36,5.46c10.32-5.34,21.77-8.12,33.38-8.12,10.39,0,20.66,2.23,30.12,6.54l1.18.54Z" />
            <path class="cls-1" d="m272,328l120.57,33.49c13.85,3.85,23.43,16.46,23.43,30.83v87.68H64v-87.68c0-14.37,9.58-26.98,23.43-30.83l120.57-33.49" />
            <path class="cls-3" d="m311.94,200l9.21-21.34c7.22-16.74,5.94-35.93-3.44-51.56-5.9-9.83-14.65-17.65-25.09-22.4l-22.33-10.17c-9.46-4.31-19.73-6.53-30.12-6.54-15.73,0-31.03,5.1-43.62,14.54l-13.36,10.33c-11.45,2.36-21.39,9.42-27.41,19.45-7.36,12.26-7.94,27.43-1.54,40.22l13.7,27.46h.06l16-32,12.8,9.6c12.46,9.35,27.62,14.4,43.2,14.4s30.74-5.05,43.2-14.4l12.8-9.6,16,32h-.06Z" />
            <path class="cls-2" d="m328,208h-16v-8l-16-32-12.8,9.6c-25.6,19.2-60.8,19.2-86.4,0l-12.8-9.6-16,32v8h-16c-8.84,0-16,7.16-16,16s7.16,16,16,16h16.47c2.73,24.3,17.62,45.56,39.53,56.42v31.58l32,32,32-32v-31.58c21.91-10.87,36.8-32.12,39.53-56.42h16.47c8.84,0,16-7.16,16-16s-7.16-16-16-16Z" />
            <path class="cls-4" d="m120,407.99h16v71.99h-16v-71.99Z" />
            <path class="cls-4" d="m344,407.99h16v71.99h-16v-71.99Z" />
        </g>
    </svg>
)


export default UsersListSvg