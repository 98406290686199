import React from 'react'

const AdminsSvg = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.391 21.8907H16.7153L17.0888 16.9219C17.1338 16.1948 16.5563 15.5806 15.8277 15.5806H8.16909C7.44047 15.5806 6.86297 16.1948 6.90797 16.9219L7.28147 21.8907H4.60547C4.08759 21.8907 3.66797 22.3103 3.66797 22.8282C3.66797 23.3461 4.08759 23.7657 4.60547 23.7657H19.391C19.9088 23.7657 20.3285 23.3461 20.3285 22.8282C20.3285 22.3103 19.9088 21.8907 19.391 21.8907ZM11.9982 19.7213C11.45 19.7213 11.0056 19.2769 11.0056 18.7287C11.0056 18.1804 11.45 17.7361 11.9982 17.7361C12.5465 17.7361 12.9908 18.1804 12.9908 18.7287C12.9908 19.2769 12.5465 19.7213 11.9982 19.7213Z" fill="black" fill-opacity="0.3"/>
        <path d="M15.7168 3.92963V4.95488C15.7168 5.214 15.6999 5.45063 15.6717 5.67038C15.6211 6.05888 15.5367 6.44213 15.4239 6.80813C15.176 7.5855 14.7987 8.301 14.2917 8.93175C14.2074 9.033 14.1226 9.13463 14.0326 9.23025L13.7341 9.55125C13.3512 9.97388 12.7989 10.2214 12.2244 10.2214H11.7736C11.2047 10.2214 10.6527 9.97913 10.2639 9.55125L9.96537 9.23025C9.87537 9.13463 9.79062 9.033 9.71187 8.93175C9.37962 8.52075 9.09237 8.06438 8.87262 7.57425C8.591 6.966 8.41063 6.32363 8.33188 5.66475C8.29813 5.43375 8.28125 5.20275 8.28125 4.97738V3.95213C8.28125 3.603 8.32625 3.25913 8.42225 2.92688C8.873 1.344 10.343 0.234375 11.999 0.234375C13.655 0.234375 15.1254 1.344 15.5757 2.9325C15.6717 3.24788 15.7168 3.59138 15.7168 3.92963Z" fill="black"/>
        <path d="M17.8448 12.7959C17.6645 12.7116 17.4788 12.6493 17.2816 12.5987L15.1467 11.8326C15.1467 11.8101 15.1411 11.7876 15.1298 11.7651L14.7526 10.8186C14.6795 10.6269 14.4485 10.5538 14.2737 10.6607L12.9162 11.4774L11.9978 12.0298L11.0798 11.4778L9.71672 10.6554C9.54197 10.5542 9.31659 10.6273 9.24347 10.8189L8.86622 11.7654C8.85497 11.7879 8.84934 11.8104 8.84934 11.8329L6.71447 12.5991C6.51722 12.6497 6.33159 12.7116 6.15122 12.7963C5.00222 13.3146 4.23047 14.4692 4.23047 15.7704V19.6569C4.23047 20.1752 4.65309 20.5922 5.17097 20.5922H6.05522L5.78559 17.0068C5.74434 16.3367 5.97909 15.6864 6.42834 15.2087C6.87797 14.7306 7.51209 14.4561 8.16834 14.4561H10.6287C10.637 14.4201 10.6493 14.3844 10.6685 14.3507L11.1698 13.4833C11.2373 13.3652 11.3615 13.2917 11.4965 13.2917H12.4992C12.6342 13.2917 12.7583 13.3648 12.8258 13.4833L13.3272 14.3507C13.3463 14.3841 13.3587 14.4197 13.367 14.4561H15.827C16.4836 14.4561 17.1177 14.7302 17.5677 15.2087C18.0177 15.6872 18.2517 16.3371 18.2112 16.9922L17.9408 20.5922H18.8255C19.3437 20.5922 19.766 20.1752 19.766 19.6569V15.7701C19.7656 14.4688 18.9938 13.3138 17.8448 12.7959Z" fill="black"/>
        </svg>
        
    )
}

export default AdminsSvg