import PropTypes from 'prop-types';
import { useMemo } from 'react';

// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import { dark, light } from './palette';
import shadows from './shadows';
import { typography } from './typography';
import GlobalStyles from './globalStyles';
import { customShadows } from './customShadows';
import { componentsOverrides } from './overrides';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(['light', 'dark']),
};

export default function ThemeProvider({ children, mode = "light" }) {

  const themeOptions = useMemo(
    () => ({
      palette: mode === "light" ? light : dark,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(mode),
      customShadows: customShadows(),
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
