'use client';

import { useCallback, useEffect, useState } from 'react';
import { Droppable, DragDropContext } from '@hello-pangea/dnd';
import { useSnackbar } from 'src/components/snackbar';
import { jwtDecode } from 'jwt-decode';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content';

import KanbanColumn from '../kanban-column';
import KanbanColumnAdd from '../kanban-column-add';
import { KanbanColumnSkeleton } from '../kanban-skeleton';

import { useSocket } from 'src/context/SocketContext';

import { ToastContainer } from 'react-toastify';
import { getItem } from 'src/lib/cookies';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import NewLeadDialog from '../new-lead-dialog';

// ----------------------------------------------------------------------

export default function KanbanView({ setState }) {
  const socket = useSocket()
  const { enqueueSnackbar } = useSnackbar();
  const [boardLoading, setBoardLoading] = useState(true);
  const [board, setBoard] = useState();
  const [leadinformation, setLeadinformation] = useState({ label: '', id: '', columnId: '' });
  const confirm = useBoolean();

  if (socket) {
    setState("connected")

  }
  const fetchTicket = (err, data) => {
    if (err) {
      enqueueSnackbar('Error in fetching data.', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: "error"
      });
      return
    }
    setBoardLoading(false)
    setBoard(data)
  }

  useEffect(() => {
    if (socket) {
      socket.emit("fetch_ticket", fetchTicket);
    }
  }, [socket])


  const newTicket = (data) => {
    const label = data?.newTicket?.labels[0]
    const id = data?.newTicket?.id
    setLeadinformation({ label: label, id: id })

    setBoard((prevBoard) => {
      const { newTicket, columnId } = data;
      const newBoard = { ...prevBoard };
      newBoard?.columns[columnId]?.taskIds.push(newTicket.id);
      newBoard.tasks[newTicket.id] = newTicket;
      return newBoard;
    });
  };


  const acceptNewTicket = (data) => {
    const label = data?.newTicket?.labels[0]
    const id = data?.newTicket?.id
    const columnId = data.columnId
    setLeadinformation({ label: label, id: id, columnId: columnId })

    if (Notification.permission === 'granted') {
      const userVisibility = (document.visibilityState === "visible");
      if (userVisibility) {
        return confirm.onTrue()
      }
    }


  };

  const userDetails = jwtDecode(getItem('token'))
  const { profileUrl, userName, email } = userDetails;
  const newAssignee = { avatarUrl: profileUrl, name: userName, id: email }

  const handleAccept = (data) => {
    const { id } = data;
    const dataToCheck = { taskId: id, action: true, flag: 1 }
    try {
      handleEmit(dataToCheck, "check_assignee", (err, response) => {
        if (response === 'You can accept this lead!') {
          handleEmit({ newAssignee, ...dataToCheck }, "update_assigneeuser", (err, updatedData) => {
            if (!err) {
              socket.emit("fetch_ticket", fetchTicket);
              enqueueSnackbar('New lead has been accepted!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Error occurred during acceptance!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'error',
              });
            }
          });
        } else {
          enqueueSnackbar('Someone else accepted this lead!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'warning',
          });
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An unexpected error occurred!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  };

  const handleReject = (data) => {
    const { id } = data;
    const dataToReject = { taskId: id, action: true, flag: 1, newAssignee }
    try {
      handleEmit(dataToReject, "reject_leads", (err, response) => {
        if (!err) {
          enqueueSnackbar('This leads rejected!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Error occurred during rejection!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        }
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An unexpected error occurred!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  };

  const moveTicket = (data) => {
    const { destination, source, draggableId } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard }
      const sourceColumnName = newBoard.columns[source.droppableId].name;
      const destinationColumnName = newBoard.columns[destination.droppableId].name
      const taskName = newBoard.tasks[draggableId].name;
      newBoard.columns[source.droppableId].taskIds.splice(source.index, 1);
      newBoard.columns[destination.droppableId].taskIds.splice(destination.index, 0, draggableId);
      newBoard.tasks[draggableId].status = newBoard.columns[destination.droppableId].name;
      enqueueSnackbar(`Task ${taskName} moved from ${sourceColumnName} to ${destinationColumnName} `, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return newBoard;
    })
  }

  const moveColumnhelper = (data) => {
    const { source, destination, draggableId } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };

      newBoard.ordered.splice(source.index, 1);

      newBoard.ordered.splice(destination.index, 0, draggableId);
      return newBoard
    })
    enqueueSnackbar('Column has been moved!', {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });

  }

  const syncColumn = (data) => {

    setBoard((prevBoard) => {
      const { columns } = data;
      const newBoard = { ...prevBoard };

      newBoard.columns = columns;
      return newBoard;
    });
  }

  const newComment = (data) => {
    const { taskId, comment } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };
      newBoard.tasks[taskId].comments.push(comment);
      enqueueSnackbar(`A new comment received for task ${newBoard.tasks[taskId].name}.`, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return newBoard;
    })
  }

  const updateTicket = (data) => {
    const { id, value, type } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };
      newBoard.tasks[id][type] = value;
      enqueueSnackbar(`Task ${newBoard.tasks[id].name} has been updated!`, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return newBoard;
    });
  }

  const addNewColumn = (data) => {
    const { newColumn } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };
      newBoard.columns[newColumn.id] = newColumn;
      newBoard.ordered.push(newColumn.id);
      return newBoard;
    })
    enqueueSnackbar('A new Column is Created!', {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  }

  const updateColumn = (data) => {
    const { id, newName } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };
      newBoard.columns[id].name = newName;
      return newBoard;
    })
    enqueueSnackbar('Column has been updated!', {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  }

  const deleteColumn = (data) => {
    const { id } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard }
      const columnName = newBoard.columns[id].name;
      delete newBoard.columns[id];
      const index = newBoard.ordered.indexOf(id);
      newBoard.ordered.splice(index, 1);
      enqueueSnackbar(`Column ${columnName}  has been deleted.`, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      return newBoard;
    })
  }

  const deleteTask = (data) => {
    const { taskId, columnId } = data;
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };

      const index = newBoard.columns[columnId].taskIds.indexOf(taskId);
      newBoard.columns[columnId].taskIds.splice(index, 1);
      if (newBoard.tasks[taskId]) {

        const taskName = newBoard.tasks[taskId].name;

        delete newBoard.tasks[taskId];
        enqueueSnackbar(`Task ${taskName} has been deleted.`, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }

      return newBoard;
    })
  }

  const updateAssignee = (data) => {
    const { newAssignee: { avatarUrl, name, id }, taskId, action, task, unAssigned = false } = data;
    if (!unAssigned) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        newBoard.tasks[taskId] = task;
        return newBoard;
      })
      return
    }

    if (unAssigned) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        delete newBoard.tasks[taskId];
        return newBoard;
      })
    }

  }
  useEffect(() => {
    if (socket) {
      socket.on("new_ticket", newTicket);

      socket.on("acceptnew_ticket", acceptNewTicket)

      socket.on("move_ticket", moveTicket);

      socket.on("sync_column", syncColumn);

      socket.on("new_comment", newComment);

      socket.on("update_ticket", updateTicket);

      socket.on("update_assignee", updateAssignee)

      socket.on("new_column", addNewColumn);

      socket.on("update_column", updateColumn);

      socket.on("delete_column", deleteColumn);

      socket.on("delete_task", deleteTask);

      socket.on("move_column", moveColumnhelper);
    }
    if (socket) {
      return () => {
        socket.off("new_ticket", newTicket);

        socket.off("acceptnew_ticket", newTicket);

        socket.off("move_ticket", moveTicket);

        socket.off("sync_column", syncColumn);

        socket.off("new_comment", newComment);

        socket.off("update_ticket", updateTicket);

        socket.off("update_assignee", updateAssignee)

        socket.off("new_column", addNewColumn);

        socket.off("update_column", updateColumn);

        socket.off("delete_column", deleteColumn);

        socket.off("delete_task", deleteTask);

        socket.off("move_column", moveColumnhelper);
      }
    }
  }, [socket])

  const handleEmit = (data, type, func) => {
    if (!socket) {
      func(true, "Please connect to server.")
      return
    }
    switch (type) {
      case "move_ticket":
        socket.emit("move_ticket", data, func);
        break;
      case "update_assignee":
        socket.emit("update_assignee", data, func);
        break;
      case "update_assigneeuser":
        socket.emit("update_assigneeuser", data, func);
        break;
      case "check_assignee":
        socket.emit("check_assignee", data, func);
        break;
      case "ticket":
        socket.emit("update_ticket", data, func);
        break;
      case "comment":
        socket.emit("add_comment", data, func);
        break;
      case "addColumn":
        socket.emit("add_column", data, func);
        break;
      case "updateColumn":
        socket.emit("update_column", data, func);
        break;
      case "deleteColumn":
        socket.emit("delete_column", data, func);
        break;
      case "deleteTask":
        socket.emit("delete_task", data, func);
        break;
      case "moveColumn":
        socket.emit("move_column", data, func);
        break;
      case "reject_leads":
        socket.emit("reject_leads", data, func);
      case "activeUsers":
        socket.emit("active_users", data, func);
        break;
      default:
        func(true);
    }
  }


  const boardEmpty = false;

  const moveColumn = async (newOrder) => {
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard }
      newBoard.ordered = newOrder;
      return newBoard;
    })
  }

  const moveTask = (newTaskOrder) => {
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard }
      newBoard.columns = newTaskOrder
      return newBoard
    })

  }

  const onDragEnd = useCallback(
    async ({ destination, source, draggableId, type }) => {
      if (!socket.connected) {
        enqueueSnackbar("Please connect to server.", {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: "error"
        });
        return
      }
      try {
        if (!destination) {
          return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
          return;
        }

        // Moving column
        if (type === 'COLUMN') {

          let newBoard;
          setBoard((prevBoard) => {
            newBoard = { ...prevBoard }
            return prevBoard;
          })

          const newOrdered = [...newBoard.ordered];

          newOrdered.splice(source.index, 1);

          newOrdered.splice(destination.index, 0, draggableId);

          moveColumn(newOrdered);

          const data = { source, destination, draggableId };
          handleEmit(data, "moveColumn", (err, data) => {
            if (err) {
              enqueueSnackbar(data, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: "error"
              });
              setBoard(newBoard);
            }
            else {
              enqueueSnackbar('Column moved successfully!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              });
            }
          })

          return;
        }

        let newBoard;
        let sourceColumn;
        let destinationColumn

        setBoard((prevalue) => {
          newBoard = { ...prevalue };

          sourceColumn = newBoard?.columns[source.droppableId];
          destinationColumn = newBoard?.columns[destination.droppableId];

          return prevalue;
        })

        // Moving task to same list
        if (sourceColumn.id === destinationColumn.id) {

          // if moving up to down
          if (source.index < destination.index) {

            let cd = 0
            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (sourceColumn.taskIds[i] === draggableId) {
                source.index = i;
                break;
              }
            }

            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (newBoard.tasks[sourceColumn.taskIds[i]]) {
                cd++;
              }

              if (cd === (destination.index + 1)) {
                destination.index = i
                break
              }
            }

          }
          // when moving down to up
          else {
            let cd = 0
            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (sourceColumn.taskIds[i] === draggableId) {
                source.index = i;
                break;
              }
            }

            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (newBoard.tasks[sourceColumn.taskIds[i]]) {

                cd++;
              }

              if (cd === (destination.index)) {
                destination.index = i + 1
                break
              }
            }

          }

          const newTaskIds = [...sourceColumn.taskIds];

          newTaskIds.splice(source.index, 1);

          newTaskIds.splice(destination.index, 0, draggableId);

          const finalColumn = {
            ...newBoard?.columns,
            [sourceColumn.id]: {
              ...sourceColumn,
              taskIds: newTaskIds,
            },
          }


          moveTask(finalColumn);
          const agentEmail = newBoard.tasks[draggableId].assignedAdmin;
          const data = { destination, source, draggableId, agentEmail }

          handleEmit(data, "move_ticket", (err, data) => {
            if (err) {
              setBoard(newBoard);
              enqueueSnackbar(data, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: "error"
              });
              return
            }
            else {
              enqueueSnackbar('Task moved successfully!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              });
            }
          })


          return;
        }

        let cd = 0
        for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
          if (sourceColumn.taskIds[i] === draggableId) {
            source.index = i;
            break;

          }
        }

        for (let i = 0; i < destinationColumn.taskIds?.length; i++) {
          if (newBoard.tasks[destinationColumn.taskIds[i]]) {

            cd++;
          }

          if (cd === (destination.index)) {
            destination.index = i + 1
            break;
          }
        }

        // Moving task to different list
        const sourceTaskIds = [...sourceColumn.taskIds];

        const destinationTaskIds = [...destinationColumn.taskIds];

        // Remove from source
        sourceTaskIds.splice(source.index, 1);

        // Insert into destination
        destinationTaskIds.splice(destination.index, 0, draggableId);

        let finalColumn;


        finalColumn = {
          ...newBoard?.columns,
          [sourceColumn.id]: {
            ...sourceColumn,
            taskIds: sourceTaskIds,
          },
          [destinationColumn.id]: {
            ...destinationColumn,
            taskIds: destinationTaskIds,
          },
        }
        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          newBoard.tasks[draggableId].status = newBoard.columns[destination.droppableId].name;

          return prevBoard
        })

        moveTask(finalColumn)

        const agentEmail = newBoard.tasks[draggableId].assignedAdmin;
        const data = { destination, source, draggableId, agentEmail }

        handleEmit(data, "move_ticket", (err, data) => {
          if (err) {
            enqueueSnackbar(data, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "error"
            });
            setBoard(newBoard);
            return
          }
          else {
            enqueueSnackbar('Task moved successfully!', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "success"
            });
          }
        })
      } catch (error) {
        console.error(error);
      }
    },
    [board?.columns, board?.ordered]
  );

  const renderSkeleton = (
    <Stack direction="row" alignItems="flex-start" spacing={3}>
      {[...Array(4)].map((_, index) => (
        <KanbanColumnSkeleton key={index} index={index} />
      ))}
    </Stack>
  );

  return (
    <>
      <ToastContainer style={{ marginTop: "40px" }} />

      <Container
        maxWidth={false}
        sx={{
          height: 1,
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        }}
      >

        {boardLoading && renderSkeleton}

        {boardEmpty && (
          <EmptyContent
            filled
            title="No Data"
            sx={{
              py: 10,
              maxHeight: { md: 480 },
            }}
          />
        )}

        {!!board?.ordered?.length && (

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="board" type="COLUMN" direction="horizontal">
              {(provided) => (
                <Scrollbar
                  sx={{
                    height: 1,
                    minHeight: {
                      xs: '80vh',
                      md: 'unset',
                    },
                    '&.simplebar-placeholder': {
                      width: '0px',
                      height: '0px'
                    }
                  }}
                >
                  <Stack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    spacing={3}
                    direction="row"
                    alignItems="flex-start"
                    sx={{
                      p: 0.25,
                      height: 1,
                    }}

                  >
                    {board?.ordered.map((columnId, index) => (
                      <KanbanColumn
                        index={index}
                        key={columnId}
                        column={board?.columns[columnId]}
                        tasks={board?.tasks}
                        board={board}
                        setBoard={setBoard}
                        handleEmit={handleEmit}
                      />
                    ))}

                    {provided.placeholder}

                    <KanbanColumnAdd addNewColumn={addNewColumn} handleEmit={handleEmit} />
                  </Stack>
                </Scrollbar>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Container>

      <NewLeadDialog
        open={confirm.value}
        setOpen={confirm.onFalse}
        handleAccept={handleAccept}
        handleReject={handleReject}
        details={leadinformation}
      />
    </>
  );
}
