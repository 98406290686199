import { styled } from "@mui/material";
import Details from "src/sections/profile/details";

const MainSection = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '74px 10px 10px 77px',
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: '100vh'
}));

const Profile = () => {
    return (
        <MainSection>
            <Details />
        </MainSection>
    )
}
export default Profile;