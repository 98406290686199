import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';

import { useBoolean } from 'src/hooks/use-boolean';
import { useSnackbar } from 'src/components/snackbar';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomDateRangePicker, { useDateRangePicker } from 'src/components/custom-date-range-picker';

import KanbanInputName from './kanban-input-name';
import KanbanDetailsToolbar from './kanban-details-toolbar';
import KanbanContactsDialog from './kanban-contacts-dialog';
import KanbanDetailsPriority from './kanban-details-priority';
import KanbanDetailsAttachments from './kanban-details-attachments';
import KanbanDetailsCommentList from './kanban-details-comment-list';
import KanbanDetailsCommentInput from './kanban-details-comment-input';
import { Typography } from '@mui/material';
import KanbanActivityDialog from './kanban-activity-dialog';
import ReadMore from '../../components/ReadMore/ReadMore'
// ----------------------------------------------------------------------

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  width: 100,
  flexShrink: 0,
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightSemiBold,
}));

// ----------------------------------------------------------------------

export default function KanbanDetails({
  task,
  openDetails,
  onCloseDetails,
  //
  onUpdateTask,
  onDeleteTask,
  board,
  setBoard,
  handleEmit
}) {

  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  }

  const { enqueueSnackbar } = useSnackbar();
  const [priority, setPriority] = useState(task.priority);

  const [taskName, setTaskName] = useState(task.name);

  const like = useBoolean();

  const contacts = useBoolean();

  const [taskDescription, setTaskDescription] = useState(task.description);

  const [taskMRR, setTaskMRR] = useState(task.mrr);

  const [due, setDue] = useState(task.due);

  const rangePicker = useDateRangePicker(due[0], due[1]);

  const [statusMapper, setStatusMapper] = useState({});
  const [allStatus, setAllStatus] = useState([]);
  
  useEffect(() => {
    setTaskDescription(task.description)
  }, [task.description])

  useEffect(() => {
    setTaskMRR(task.mrr)
  }, [task.mrr])

  useEffect(() => {
    setPriority(task.priority)
  }, [task.priority])

  useEffect(() => {
    setDue(task.due)
  }, [task.due]);

  useEffect(() => {
    setTaskName(task.name)
  }, [task.name])

  useEffect(() => {
    const map = {};
    const allStatus = [];
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard };

      newBoard.ordered.map((columnId) => {
        const columnName = newBoard.columns[columnId].name;
        map[columnName] = columnId;
        allStatus.push(columnName);
      })
      return prevBoard;
    })
    setStatusMapper(map);
    setAllStatus(allStatus);
  }, [board])

  const onUpdateDueDate = () => {
    try {
      const eDate = new Date(rangePicker.endDate).getTime();
      const sDate = new Date(rangePicker.startDate).getTime();
      const value = [sDate, eDate];
      const data = {
        id: task.id,
        value,
        type: "due",
        agentEmail: task.assignedAdmin
      }
      handleEmit(data, "ticket", (err) => {
        if (!err) {
          const newTask = { ...task };
          newTask.due = value;
          onUpdateTask(newTask);
          enqueueSnackbar("Updated successfully.", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
          });
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
        }
      })

    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeTaskName = useCallback((event) => {
    setTaskName(event.target.value);
  }, []);

  const handleComment = useCallback((messageType, message) => {
    try {
      const data = { id: task.id, messageType, message, agentEmail: task.assignedAdmin };
      handleEmit(data, "comment", (err, comment) => {
        if (!err) {
          const newTask = { ...task };
          newTask.comments.push(comment)
          onUpdateTask(newTask);
          enqueueSnackbar("Your comment has been successfully added to the task.", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
        }
      })

    } catch (error) {
      console.error(error);
    }
  })

  // const handleAttachment = useCallback((file) => {
  //   try {
  //     const data = { id: task.id, agentEmail: task.assignedAdmin, file };
  //     handleEmit(data, "attachment", (err, attachment) => {
  //       if (!err) {
  //         console.log("attachment", attachment)
  //       }
  //     })
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [task])

  const handleUpdateDescription = () => {
    try {
      const data = {
        id: task.id,
        value: taskDescription,
        type: "description",
        agentEmail: task.assignedAdmin
      }

      handleEmit(data, "ticket", (err, data) => {
        if (!err) {
          onUpdateTask({
            ...task,
            description: taskDescription
          })
          enqueueSnackbar("The task description has been updated successfully.", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
        }
      })

    } catch (error) {
      console.error(error);

    }
  }

  const handleUpdateMRR = () => {
    try {
      const data = {
        id: task.id,
        value: taskMRR,
        type: "mrr",
        agentEmail: task.assignedAdmin
      }

      handleEmit(data, "ticket", (err, data) => {
        if (!err) {
          onUpdateTask({
            ...task,
            mrr: taskMRR
          })
          enqueueSnackbar("The task MRR has been updated successfully.", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
        }
      })

    } catch (error) {
      console.error(error);

    }
  }

  const handleUpdateTask = useCallback(
    (event) => {
      try {
        if (event.key === 'Enter') {
          if (taskName) {

            const data = {
              id: task.id,
              value: taskName,
              type: "name",
              agentEmail: task.assignedAdmin
            }

            handleEmit(data, "ticket", (err, data) => {
              if (!err) {
                onUpdateTask({
                  ...task,
                  name: taskName,
                });
                enqueueSnackbar("The task name has been successfully updated.", {
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });
              }
              else {
                enqueueSnackbar(data, {
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  variant: "error"
                });
              }
            })
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onUpdateTask, task, taskName]
  );

  const handleChangeTaskDescription = useCallback((event) => {
    setTaskDescription(event.target.value);
  }, []);

  const handleChangeTaskMRR = useCallback((event) => {
    setTaskMRR(event.target.value);
  }, []);

  const handleChangePriority = useCallback((newValue) => {
    try {
      setPriority(newValue);
      const data = {
        id: task.id,
        value: newValue,
        type: "priority",
        agentEmail: task.assignedAdmin
      }

      handleEmit(data, "ticket", (err, data) => {
        if (!err) {
          const newTask = { ...task };
          newTask.priority = newValue;
          onUpdateTask(newTask);
          enqueueSnackbar("The task priority has been successfully updated.", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
          });
          reset();
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
        }
      })

    } catch (error) {
      console.error(error);
    }
  }, [task]);

  const handleAssignee = (contact, checked) => {
    try {

      handleEmit({ newAssignee: contact, taskId: task.id, action: !checked, flag: 1 }, "update_assignee", (err, data) => {
        if (!err) {
          const newTask = { ...task }
          if (!checked) {
            const { name, avatarUrl, id } = contact;
            const assignee = { name, id, avatarUrl }
            newTask.assignee.push(assignee);
            onUpdateTask(newTask);
          }
          else {
            let index = -1;
            newTask.assignee.forEach((item, i) => {
              if (item.id === contact.id) {
                index = i;
              }
            });
            if (index !== -1) {
              newTask.assignee.splice(index, 1)
              onUpdateTask(newTask);
            }
          }
          enqueueSnackbar("The task assignee has been successfully updated.", {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
        }
      })
    } catch (error) {
      console.error(error);
    }

  }

  const handleUpdateStatus = (oldStatus, updatedStatus) => {
    try {
      const newBoard = { ...board };
      const sourceIndex = board.columns[oldStatus].taskIds.findIndex(x => x == task.id);
      if (sourceIndex < 0) {
        return
      }
      const sourceColumn = board?.columns[oldStatus];

      const destinationIndex = 0;
      const destinationColumn = board?.columns[updatedStatus]
      const sourceTaskIds = [...sourceColumn.taskIds];

      const destinationTaskIds = [...destinationColumn.taskIds];

      // Remove from source
      sourceTaskIds.splice(sourceIndex, 1);

      // Insert into destination
      destinationTaskIds.splice(destinationIndex, 0, task.id);

      const finalColumn = {
        ...newBoard?.columns,
        [sourceColumn.id]: {
          ...sourceColumn,
          taskIds: sourceTaskIds,
        },
        [destinationColumn.id]: {
          ...destinationColumn,
          taskIds: destinationTaskIds,
        },
      }

      const source = { droppableId: oldStatus, index: sourceIndex }
      const destination = { droppableId: updatedStatus, index: 0 }
      const draggableId = task.id;
      const data = { destination, source, draggableId, agentEmail: task.assignedAdmin }

      handleEmit(data, "move_ticket", (err, data) => {
        if (err) {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
          return
        }
        else {
          setBoard((prevBoard) => {
            const newBoard = { ...prevBoard }
            newBoard.columns = finalColumn;
            const newTask = { ...task };
            newTask.status = newBoard.columns[updatedStatus].name;
            newBoard.tasks[task.id] = newTask;

            return newBoard
          })
          enqueueSnackbar('Task moved successfully!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "success"
          });
        }
      })

    } catch (error) {
      console.error(error);
    }
  }

  const renderHead = (
    <KanbanDetailsToolbar
      task={task}
      liked={like.value}
      taskName={task.name}
      onLike={like.onToggle}
      onDelete={onDeleteTask}
      taskStatus={task.status}
      handleUpdateStatus={handleUpdateStatus}
      statusMapper={statusMapper}
      allStatus={allStatus}
      onCloseDetails={onCloseDetails}
      handleEmit={handleEmit}
    />
  );

  const renderName = (
    <KanbanInputName
      placeholder="Task name"
      value={taskName}
      onChange={handleChangeTaskName}
      onKeyUp={handleUpdateTask}
    />
  );

  const renderClientName = (
    <Stack direction="row" alignItems="center">
      <StyledLabel>Client Name</StyledLabel>
      <Typography variant='h7'>
        {task.userName}
      </Typography>
    </Stack>
  );

  const renderClientEmail = (
    <Stack direction="row" alignItems="center">
      <StyledLabel>Client Email</StyledLabel>
      <Typography variant='h7'>
        {task.email}
      </Typography>
    </Stack>
  );

  const renderClientPhone = (
    <Stack direction="row" alignItems="center">
      <StyledLabel>Client Contact</StyledLabel>
      <Typography variant='h7'>
        {task.phone}
      </Typography>
    </Stack>
  );

  const renderReporter = (
    <Stack direction="row" alignItems="center">
      <StyledLabel>Reporter</StyledLabel>
      <Avatar alt={task.reporter.name} src={task.reporter.avatarUrl} />
    </Stack>
  );

  const renderAssignee = (
    <Stack direction="row">
      <StyledLabel sx={{ height: 40, lineHeight: '40px' }}>Assignee</StyledLabel>

      <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
        {task.assignee.map((user) => (
          <Tooltip title={user.name || "User"}>
            <Avatar key={user.id} alt={user.name} src={user.avatarUrl} />
          </Tooltip>
        ))}

        <Tooltip title="Add assignee">
          <IconButton
            onClick={contacts.onTrue}
            sx={{
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
              border: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>
        </Tooltip>

        <KanbanContactsDialog
          assignee={task.assignee}
          open={contacts.value}
          onClose={contacts.onFalse}
          handleAssignee={handleAssignee}
          handleEmit={handleEmit}
        />
      </Stack>
    </Stack>
  );

  const renderActivity = (
    <Stack direction="row">
      <StyledLabel sx={{ height: 40, lineHeight: '40px' }}>Activity</StyledLabel>

      <Stack spacing={1} width={"100%"}>

        <KanbanActivityDialog
          key={seed}
          taskId={task.id}
        />
      </Stack>
    </Stack>
  );

  const renderLabel = (
    <Stack direction="row">
      <StyledLabel sx={{ height: 24, lineHeight: '24px' }}>Labels</StyledLabel>

      {!!task.labels.length && (
        <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
          {task.labels.map((label) => (
            <Chip key={label} color="info" label={label} size="small" variant="soft" />
          ))}
        </Stack>
      )}
    </Stack>
  );

  const renderOrigin = (
    <Stack direction="row">
      <StyledLabel sx={{ height: 24, lineHeight: '24px' }}>Origin</StyledLabel>

      {!!task.origin && (
        <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
          <Chip key={task.origin} color="success" label={task.origin} size="small" variant="soft" />
        </Stack>
      )}
    </Stack>
  );
  const renderDueDate = (
    <Stack direction="row" alignItems="center">
      <StyledLabel> Due date </StyledLabel>

      {rangePicker?.showDate ? (
        <Button size="small" onClick={rangePicker.onOpen}>
          {rangePicker?.showDate}
        </Button>
      ) : (
        <Tooltip title="Add due date">
          <IconButton
            onClick={rangePicker.onOpen}
            sx={{
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
              border: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>
        </Tooltip>
      )}

      <CustomDateRangePicker
        variant="calendar"
        title="Choose due date"
        startDate={rangePicker.startDate}
        endDate={rangePicker.endDate}
        onChangeStartDate={rangePicker.onChangeStartDate}
        onChangeEndDate={rangePicker.onChangeEndDate}
        open={rangePicker.open}
        onClose={rangePicker.onClose}
        selected={rangePicker.selected}
        error={rangePicker.error}
        onUpdateDueDate={onUpdateDueDate}
      />
    </Stack>
  );

  const renderPriority = (
    <Stack direction="row" alignItems="center">
      <StyledLabel>Priority</StyledLabel>

      <KanbanDetailsPriority priority={priority} onChangePriority={handleChangePriority} />
    </Stack>
  );

  const renderRequirement = (
    <Stack direction="row">
      <StyledLabel> Requirement </StyledLabel>
      <Typography sx={{ width: '75%' }}>
        {task?.requirement?.length > 0 && (<ReadMore text={task?.requirement} initialChunkLength={50} chunkLength={500} />)}
      </Typography>
    </Stack>
  );

  const renderDescription = (
    <Stack direction="row">
      <StyledLabel> Description </StyledLabel>

      <TextField
        fullWidth
        multiline
        size="small"
        value={taskDescription}
        onChange={handleChangeTaskDescription}
        onBlur={handleUpdateDescription}
        InputProps={{
          sx: { typography: 'body2' },
        }}
      />
    </Stack>
  );

  const renderMRR = (
    <Stack direction="row">
      <StyledLabel> MRR </StyledLabel>

      <TextField
        fullWidth
        multiline
        size="small"
        value={taskMRR}
        onChange={handleChangeTaskMRR}
        onBlur={handleUpdateMRR}
        InputProps={{
          sx: { typography: 'body2' },
        }}
      />
    </Stack>
  );

  const renderCreatedDate = (
    <Stack direction="row">
      <StyledLabel sx={{ height: 24, lineHeight: '24px' }}>Created Date</StyledLabel>
      {!!task.createdAt.length && (
        <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
          {moment(task.createdAt).format('D MMM, YYYY hh:mm:ss')}
        </Stack>
      )}
    </Stack>
  );
  // const renderAttachments = (
  //   <Stack direction="row">
  //     <StyledLabel>Attachments</StyledLabel>
  //     <KanbanDetailsAttachments attachments={task.attachments} handleAttachment={handleAttachment} />
  //   </Stack>
  // );

  const renderComments = <KanbanDetailsCommentList comments={task.comments} />;

  return (
    <Drawer
      open={openDetails}
      onClose={onCloseDetails}
      anchor="right"
      slotProps={{
        backdrop: { invisible: true },
      }}
      PaperProps={{
        sx: {
          width: {
            xs: 1,
            sm: 480,
          },
        },
      }}
    >
      {renderHead}

      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
          overflowY: 'scroll'
        }}
      >
        <Stack
          spacing={3}
          sx={{
            pt: 3,
            pb: 5,
            px: 2.5,
          }}
        >
          {renderName}

          {renderClientName}

          {renderClientEmail}

          {renderClientPhone}

          {/* {renderReporter} */}

          {renderAssignee}

          {renderLabel}
          {renderOrigin}

          {renderDueDate}

          {renderPriority}
          {renderRequirement}
          {renderCreatedDate}
          {renderMRR}
          {renderDescription}

          {/* {renderAttachments} */}

          {renderActivity}
        </Stack>

        {!!task.comments.length && renderComments}
      </Scrollbar>

      <KanbanDetailsCommentInput handleComment={handleComment} />
    </Drawer>
  );
}

KanbanDetails.propTypes = {
  onCloseDetails: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
  openDetails: PropTypes.bool,
  task: PropTypes.object,
};
