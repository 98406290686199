import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Box, Menu, Typography } from "@mui/material";
import { jwtDecode } from 'jwt-decode';
import { getItem } from 'src/lib/cookies';


import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import axios from 'axios';
import { CALL_CLIENT_URL } from '../../config/config.local'

// ----------------------------------------------------------------------

export default function KanbanDetailsToolbar({
  task,
  liked,
  onLike,
  taskName,
  onDelete,
  taskStatus,
  handleUpdateStatus,
  statusMapper,
  allStatus,
  onCloseDetails,
  handleEmit
}) {
  const smUp = useResponsive('up', 'sm');

  const confirm = useBoolean();

  const popover = usePopover();

  const [status, setStatus] = useState(taskStatus);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCall, setAnchorElCall] = useState(null);
  const openInfo = Boolean(anchorEl);
  const openInfoCall = Boolean(anchorElCall);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeStatus = useCallback(
    (newValue) => {
      popover.onClose();
      const oldStatus = statusMapper[status];
      setStatus(newValue);
      handleUpdateStatus(oldStatus, statusMapper[newValue])
    },
    [popover]
  );
  const userDetails = jwtDecode(getItem('token'))
  const { profileUrl, userName, email } = userDetails;
  const newAssignee = { avatarUrl: profileUrl, name: userName, id: email }

  const handleCallForward = async () => {
    try {
      setAnchorElCall(null)
      onCloseDetails()
      const clickToCallOriginated = await axios.post(CALL_CLIENT_URL)
      const { callId, status } = clickToCallOriginated.data;
      if (status === "success") {
        enqueueSnackbar('Call forwarded successfully', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
        });
        const dataClickToCall = { taskId: task.id, action: 'clickToCall', flag: 1, newAssignee, callId, status }
        handleEmit(dataClickToCall, "reject_leads", (err, response) => {
          if (!err) {
            console.log("saved cilckTocall record!")
          } else {
            enqueueSnackbar('Error occurred during saving record!', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
            });
          }
        })
      } else {
        console.log("call not connect!")
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An error occured during call forward!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  };

  const companySizeLabel = (companySize) => {
    switch (companySize) {
      case '1':
        return '1-25';
      case '2':
        return '25-100';
      case '3':
        return '100-200';
      case '4':
        return '200-500';
      case '5':
        return '500+';
      default:
        return '0';
    }
  };


  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          p: (theme) => theme.spacing(2.5, 1, 2.5, 2.5),
          gap: '6px'
        }}
      >
        {!smUp && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Button
          size="small"
          variant="soft"
          endIcon={<Iconify icon="eva:arrow-ios-downward-fill" width={16} sx={{ ml: -0.5 }} />}
          onClick={popover.onOpen}
        >
          {status}
        </Button>
        {/* <Button
          onClick={(e) => setAnchorElCall(e.currentTarget)}
          autoFocus
          variant='contained'
          size='small'
          color='primary'
          sx={{ color: '#fff', marginRight: '8px', boxShadow: 'none' }}>Click To Call</Button> */}

        <Stack direction="row" justifyContent="flex-end" flexGrow={1} spacing={1}>
          {/* <Tooltip title="Like">
            <IconButton color={liked ? 'default' : 'primary'} onClick={onLike}>
              <Iconify icon="ic:round-thumb-up" />
            </IconButton>
          </Tooltip> */}

          <Tooltip title="Delete task">
            <IconButton onClick={confirm.onTrue}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>

          <Tooltip title="More Detailed info">
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {/* <IconButton onClick={onCloseDetails}> */}
              <Iconify icon="ep:info-filled" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Close">
            <IconButton onClick={onCloseDetails}>
              <Iconify icon="uiw:circle-close" />
            </IconButton>
          </Tooltip>

          {/* <IconButton>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-right"
        sx={{ width: 140 }}
      >
        {allStatus.map((option) => (
          <MenuItem
            key={option}
            selected={status === option}
            onClick={() => {
              handleChangeStatus(option);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {taskName} </strong>?
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDelete();
            confirm.onFalse();
          }}>
            Delete
          </Button>
        }
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openInfo}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              minWidth: 200,
              maxWidth: 600,
              borderRadius: 2,
              '& .MuiMenuItem-root': {
                px: 2,
                py: 1.5,
              },
            },
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          {[
            {
              label: 'Request Type', value: task.requestType?.includes('corporate') || task.requestType?.includes('corporateFor')
                ? 'Corporate'
                : task.requestType?.includes('individual') || task.requestType?.includes('individualReq')
                  ? 'Individual'
                  : task.requestType,
            },
            { label: 'Company Name', value: task.companyName },
            { label: 'Company Size', value: companySizeLabel(task.companySize) },
            { label: 'Company Address', value: task.companyAddress },
            { label: 'City', value: task.city },
          ].map((item, index) => (
            <MenuItem
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 1,
                fontWeight: 600,
                color: 'grey.700',
                '&:hover': {
                  color: 'grey.800',
                  backgroundColor: 'grey.200',
                },
              }}
            >
              <Typography variant="body1" sx={{ color: 'grey.700', fontSize: 12, fontWeight: 'bold' }}>
                {item.label}:
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'grey.700', fontSize: 14 }}>
                {item.value || 'N/A'}
              </Typography>
            </MenuItem>
          ))}
        </Menu>

      </Box>

      <ConfirmDialog
        anchorEl={anchorElCall}
        open={openInfoCall}
        onClose={() => setAnchorElCall(null)}
        title="Click to call"
        content={
          <>
            Are you sure want to make a call for<strong> {taskName} </strong>?
          </>
        }
        action={
          <Button padding='5px' variant="contained" color="success" onClick={() => {
            handleCallForward()
          }}>
            < AddIcCallIcon style={{ marginRight: '8px' }} />
            Call
          </Button>
        }
      />
    </>
  );
}

KanbanDetailsToolbar.propTypes = {
  liked: PropTypes.bool,
  onCloseDetails: PropTypes.func,
  onDelete: PropTypes.func,
  onLike: PropTypes.func,
  taskName: PropTypes.string,
  taskStatus: PropTypes.string,
};
