import React from 'react'

const LeadsSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.32" d="M20.0589 7.55059V10.3511H15.0409V8.91745C15.0409 8.11921 14.6778 7.36895 14.0469 6.87668L14.0566 6.86249C14.3767 5.73458 15.4042 4.96042 16.5751 4.95557H17.4735C18.9024 4.96042 20.0589 6.11689 20.0589 7.55059C20.0589 7.54573 20.0589 7.54573 20.0589 7.55059Z" fill="#212B36" />
            <path opacity="0.32" d="M18.7557 2.14099V2.15537C18.7557 3.11583 17.9815 3.89484 17.0209 3.89969H16.997C16.0268 3.89969 15.2383 3.11583 15.2383 2.14099V2.13614C15.2383 1.16597 16.0268 0.377441 16.997 0.377441C17.9671 0.382295 18.7557 1.17083 18.7557 2.14099Z" fill="#212B36" />
            <path d="M16.1432 16.105L16.1384 17.6869L14.0117 17.6916L14.0166 16.1098L16.1432 16.105Z" fill="black" />
            <path opacity="0.32" d="M16.139 17.6868V19.498C16.1295 21.7728 14.2847 23.6174 12.0101 23.6269H11.9957C9.73053 23.6269 7.89062 21.787 7.89062 19.5219C7.89062 19.517 7.89062 19.517 7.89062 19.5124L7.89529 17.5674L10.0219 17.5625L10.0173 19.5028C10.0173 20.602 10.9156 21.4956 12.0147 21.4956C13.1139 21.4956 14.0077 20.5971 14.0077 19.498L14.0124 17.6868H16.139Z" fill="#212B36" />
            <path d="M15.0388 10.3512V11.7227H8.96484V8.91265C8.96951 7.48381 10.126 6.32715 11.5597 6.32715H12.4582C13.8872 6.32715 15.0437 7.48848 15.0437 8.9175V10.3512H15.0388Z" fill="black" />
            <path d="M10.026 16.1147V17.5677L7.89453 17.5723L7.89938 16.1196L10.026 16.1147Z" fill="black" />
            <path opacity="0.32" d="M9.94341 6.84326L9.96245 6.86716C9.33167 7.35457 8.96372 8.10969 8.96372 8.90774V10.3511H3.96484V7.54107C3.9697 6.11204 5.12617 4.95557 6.55501 4.95557H7.44397C8.6053 4.96042 9.62307 5.72972 9.94341 6.84326Z" fill="#212B36" />
            <path opacity="0.32" d="M8.75959 2.14102C8.75473 2.6094 8.56357 3.05855 8.2339 3.38822C7.90889 3.71808 7.46441 3.89972 7.00089 3.89972C6.03072 3.89972 5.24219 3.11586 5.24219 2.14102C5.24219 1.17085 6.03072 0.382324 7.00089 0.382324H7.00574C7.97106 0.382324 8.75959 1.17085 8.75959 2.14102Z" fill="#212B36" />
            <path d="M8.81943 14.92C9.02963 14.9154 9.20175 14.7432 9.20175 14.5282L9.20642 13.1949C9.20642 12.9845 9.03915 12.8126 8.82895 12.8126C8.61857 12.8077 8.4513 12.975 8.44664 13.1852V13.1901L8.44178 14.5282C8.43226 14.7432 8.60438 14.9154 8.81943 14.92Z" fill="black" />
            <path d="M10.4991 15.6276L11.4405 14.6862C11.5887 14.5333 11.5839 14.2943 11.4358 14.1461C11.2876 14.0027 11.0487 14.0027 10.9006 14.1461L9.95901 15.0875C9.80612 15.2357 9.80612 15.4794 9.95434 15.6276C10.1024 15.7758 10.3462 15.7805 10.4944 15.6324C10.4944 15.6324 10.4944 15.6324 10.4991 15.6276Z" fill="black" />
            <path d="M7.91331 15.3834C8.06135 15.2353 8.06135 14.9915 7.91331 14.8435L6.9717 13.9019C6.82367 13.749 6.57986 13.749 6.43183 13.8972C6.27875 14.0452 6.27875 14.2891 6.42697 14.4373L6.43183 14.4419L7.37325 15.3834C7.52128 15.5364 7.76509 15.5364 7.91331 15.3834Z" fill="black" />
            <path d="M15.1597 14.8724C15.3652 14.8773 15.5373 14.7147 15.542 14.5093V14.5045V13.1854C15.542 12.9801 15.3747 12.8175 15.174 12.8175C15.1692 12.8175 15.1645 12.8175 15.1597 12.8175C14.9541 12.8128 14.7822 12.9752 14.7773 13.1808V13.1903V14.5093C14.7773 14.7147 14.9446 14.8773 15.1501 14.8773C15.1501 14.8724 15.155 14.8724 15.1597 14.8724Z" fill="black" />
            <path d="M17.6864 14.557C17.8344 14.4088 17.8344 14.165 17.6864 14.0169C17.5382 13.8687 17.2944 13.8687 17.1463 14.0169L16.2049 14.9583C16.0567 15.1066 16.0567 15.3502 16.2049 15.4984C16.353 15.6464 16.5968 15.6464 16.7448 15.4984L17.6864 14.557Z" fill="black" />
            <path d="M14.0116 15.4891C14.2219 15.4891 14.3939 15.3169 14.3939 15.1067C14.3939 15.0063 14.3556 14.9059 14.2839 14.8342L13.3425 13.8928C13.1945 13.7447 12.9507 13.7447 12.8024 13.8928C12.6544 14.041 12.6544 14.2846 12.8024 14.4328L13.744 15.3742C13.8109 15.4508 13.9113 15.4891 14.0116 15.4891Z" fill="black" />
            <path d="M13.7791 3.50284C13.7743 3.97122 13.5831 4.42036 13.2534 4.75004C12.9284 5.0799 12.4839 5.26154 12.0204 5.26154C11.0502 5.26154 10.2617 4.47768 10.2617 3.50284C10.2617 2.53267 11.0502 1.74414 12.0204 1.74414H12.0253C12.9906 1.74414 13.7791 2.53267 13.7791 3.50284Z" fill="black" />
        </svg>
    )
}

export default LeadsSvg