import React, { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { setLoginSession } from '../utils/common';
import { axiosPostCall } from '../services/apisCall';
import { ToastContainer } from 'react-toastify';
import { renderErrorMessage } from '../lib/toastMessage';
import LoadingSpinner from '../components/LoadingSpinner';
import { removeItem } from '../lib/cookies';
import Logger from '../lib/Logger';

//@mui
import { Box, Modal, } from '@mui/material';
import { useSocket } from 'src/context/SocketContext';

const logger = new Logger("Login");

import { configFile } from '../config';
const { GOOGLE_AUTH_CLIENT_ID } = configFile;
import { LOGIN_PAGE_RELOAD_TIME_ON_ERROR } from '../config/commonConfig';

const Login = () => {
  const socket = useSocket()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(true);
  const handleGoogleLogin = async (googleToken) => {
    setLoading(true)
    try {
      const { exp, sessionToken, token } = await axiosPostCall("login", { token: googleToken });

      if (!exp || !sessionToken || !token) {
        setLoading(false)
        renderErrorMessage("internal server error")
        return window.location.href = "/";

      }
      setLoginSession(exp, sessionToken, token);
      socket.connect();
      socket.on('connect', () => {
        console.log('Connected to socket server in login');
      });
      return window.location.href = "/home";
    } catch (error) {
      logger.error("home.jsx , handleGoogleLogin,login", error);
      renderErrorMessage(error.message);
      setTimeout(() => {
        setLoading(false)
        return window.location.href = "/";
      }, LOGIN_PAGE_RELOAD_TIME_ON_ERROR);
    }
  }

  useState(() => {
    removeItem("g_state")
  }, [])

  return (
    <Box sx={{ outline: 'none' }}>
      {/* <Modal
        open={open}
        sx={{
          outline: 0
        }}
      >
        <Box>
          <ToastContainer />
          {/* <Box sx={{
            // backgroundColor: '#000000',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none'
          }}>
            {loading && <LoadingSpinner />}
          </Box> */}
      <Box sx={{
        // display: 'none'
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center"

      }}>

        <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
          <GoogleLogin
            onSuccess={credentialResponse => {
              const token = credentialResponse.credential
              handleGoogleLogin(token)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            useOneTap
            cancel_on_tap_outside
          />
        </GoogleOAuthProvider>
      </Box>
    </Box>
    // {/* </Modal> */}

  )
}

export default Login;
