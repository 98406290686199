import { useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, CircularProgress, Button } from '@mui/material';
import LeadsIcon from '../assets/svgs/leadsIcon';
import { KanbanView } from '../sections/kanban/view';
import { CheckCircle, Error } from '@mui/icons-material';
import { SnackbarProvider } from 'src/components/snackbar';
import { useNavigate } from 'react-router-dom';

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 20px 88px",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ConnectionIndicator = ({ isConnected }) => {
    const text = "You are disconnected! Try again";

    return (
        <Box style={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
            {isConnected === 'pending' && (
                <div style={{ marginRight: 8 }}>
                    <CircularProgress size={18} sx={{ color: 'green' }} />
                </div>
            )}
            {isConnected === 'connected' && (
                <CheckCircle sx={{ color: 'green', fontSize: 18 }} />
            )}
            {isConnected === 'disconnected' && (
                confirm(text) == true ? window.location.reload() : window.location.reload()
                // <Error sx={{ color: 'red', fontSize: 18 }} />
            )}

            <Box component="span" sx={{ fontWeight: 'bold' }}>
                {isConnected === 'pending' && 'Pending'}
                {isConnected === 'connected' && 'Connected'}
                {isConnected === 'disconnected' && 'Disconnected'}
            </Box>
        </Box>
    );
};

const analysisDetails= async(navigate)=>{
    navigate("/leads_analysis")
}

const Leads = () => {
    const [connect, setConnect] = useState("pending");
    const navigate = useNavigate()

    return (
        <SnackbarProvider>


            <MainSection>
                {/* Heading and filters */}
                <HeadingSection>
                    <HeadingContentSection>
                        <Box sx={{
                            widht: '16px',
                            height: '16px',
                            mt: "-10px",
                        }}>
                            <LeadsIcon />
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            Leads
                        </Typography>
                    </HeadingContentSection>
                    <Box sx={{
                        display: "flex",
                        gap: "10px"
                    }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => analysisDetails(navigate)}
                        >Analysis</Button>
                        <ConnectionIndicator isConnected={connect} />
                    </Box>
                </HeadingSection>

                {/* <CardsSection /> */}

                <KanbanView setState={setConnect} />

            </MainSection>
        </SnackbarProvider>
    )
}
export default Leads;