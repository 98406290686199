import { Typography, Box, Button } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const PermissionDenied = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
                height: window.innerHeight,
                flexDirection: 'column',
                gap: '20px'
            }}>
            <Typography sx={{
                fontSize: '120px',
                fontWeight: 600,
                lineHeight: '9.7rem'
            }}>
                Ooops.
            </Typography>

            <Typography sx={{
                fontSize: '32px',
                fontWeight: 500
            }}>
                Permission Denied!
            </Typography>

            <Button
                variant='contained'
                sx={{
                    boxShadow: 'none'
                }}
                onClick={() => navigate("/")}
            >
                Back to home
            </Button>
        </Box>
    )
}

export default PermissionDenied;