import { configFile } from '../config';
// import ErrorMessage from '../utils/ErrorMessage.json';
import PropTypes from 'prop-types';
import axiosIntance from '../lib/axios';
import { prepareMessageFromError } from '../utils/common';
const { endPoints } = configFile;
import ErrorMessage from '../utils/ErrorMessage.json';
import Logger from '../lib/Logger';
const logger = new Logger("apisCall")

// create api post call for all post method apis.
const axiosPostCall = async (endPointName, data = {}, completeResponse = false) => {
  try {
    const url = endPoints(endPointName);
    const response = await axiosIntance.post(url, data);

    if (completeResponse) return response;
    if (response?.status === 200) {
      return response?.data;
    }
    throw new Error(ErrorMessage?.[4000]);
  } catch (error) {
    throw new Error(prepareMessageFromError(error));
  }
};

axiosPostCall.propTypes = {
  endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
  data: PropTypes.object, // Method body data.
  completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
};

// create api get call for all get method apis.
const axiosGetCall = async (endPointName, data = {}, completeResponse = false) => {
  try {
    const url = endPoints(endPointName);
    const response = await axiosIntance.get(url, { params: data });

    if (completeResponse) return response;
    if (response?.status === 200) {
      return response?.data;
    }
    throw new Error(ErrorMessage?.[4000]);
  } catch (error) {
    throw new Error(prepareMessageFromError(error));
  }
};

const handleLogout = async () => {
  try {
    return await axiosPostCall("logout")
  }
  catch (error) {
    logger.error("apisCall.js [handleLogout] catchError", error);
    throw new Error(error);
  }
}

axiosGetCall.propTypes = {
  endPointName: PropTypes.string.isRequired, // Api end point url name added in config.
  data: PropTypes.object, // Method body data.
  completeResponse: PropTypes.bool, // Pass true if you want received completed response insted of data.
};

export { axiosPostCall, axiosGetCall, handleLogout };
