import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    InputLabel, Box, TextField, Select,
    OutlinedInput, Chip, MenuItem,
    FormControl, InputAdornment, Tooltip,
    IconButton, FormControlLabel, Checkbox,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { Icon } from '@iconify/react';
import { checkButtonAccess, generateRandomString, getProductList, /*getProductPermissions*/ } from '../../utils/common';
import dayjs from 'dayjs';
import Iconify from '../../components/iconify';
import { renderErrorMessage } from '../../lib/toastMessage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Radio,
    RadioGroup,
} from '@mui/material';

const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const RowItem = styled("div")(() => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "5px"
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const NewUserForm = ({ handleCreateNewUser, edit, userDetails }) => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [password, setPassword] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [code, setCode] = useState("")
    const [role, setRole] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyLocation, setCompanyLocation] = useState("")
    // const [permission, setPermission] = useState("l1")
    // const [products, setProducts] = useState([])
    // const [gender, setGender] = useState("")
    // const [maritalStatus, setMaritalStatus] = useState("")
    const [dDate, setDDate] = useState(null)
    const [flag, setFlag] = useState("");

    const [newPasswordError, setNewPasswordError] = useState("");
    const [pwdLength, setPwdLength] = useState(true);
    const [pwdNumber, setPwdNumber] = useState(true);
    const [pwdUppercase, setPwdUppercase] = useState(true);
    const [pwdLowercase, setPwdLowercase] = useState(true);
    const [pwdSymbol, setPwdSymbol] = useState(true);
    const [userName, setUserName] = useState("");
    const [loginLimit, setLoginLimit] = useState(1);
    const [productPermissions, setProductPermissions] = useState([]);

    const [userNameError, setUserNameError] = useState("");
    const [userNameLength, setUserNameLength] = useState(false);
    const [userNameSymbol, setUserNameSymbol] = useState(false);

    const [products, setProducts] = useState(["Console"]);
    const tomorrow = dayjs().add(1, 'day');

    const theme = useTheme();



    const [optionalParams, setOptionalParams] = useState([
        {
            fieldTitle: "",
            fieldValue: ""
        }
    ])

    const [isOptionalParameter, setIsOptionalParameter] = useState(false);

    const handleChange = (event) => {
        setIsOptionalParameter(event.target.checked);
    };

    const onChangeOptonalParams = (childIndex, key, value) => {
        const newData = [...optionalParams];
        newData[childIndex][key] = value;
        setOptionalParams(newData);
    }

    const deleteOptionalParameter = (childIndex) => {
        const newData = [...optionalParams];
        newData.splice(childIndex, 1);
        setOptionalParams(newData);
    }

    const addOptionalParameter = () => {
        const newData = [...optionalParams];

        const newObj = {
            fieldTitle: "",
            fieldValue: ""
        }

        newData.push(newObj);
        setOptionalParams(newData);
    }

    useEffect(() => {
        if (!password) {
            return setNewPasswordError("blank")
        };

        setNewPasswordError("");
        (password.length >= 8 && password.length <= 20) ? setPwdLength(true) : setPwdLength(false);
        (/[0-9]/.test(password)) ? setPwdNumber(true) : setPwdNumber(false);
        (/[A-Z]/.test(password)) ? setPwdUppercase(true) : setPwdUppercase(false);
        (/[a-z]/.test(password)) ? setPwdLowercase(true) : setPwdLowercase(false);
        // eslint-disable-next-line
        (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/).test(password) ? setPwdSymbol(true) : setPwdSymbol(false);
    }, [password])

    useEffect(() => {
        if (!userName) {
            return setUserNameError("blank");
        }

        setUserNameError("");
        /^.{4,20}$/.test(userName) ? setUserNameLength(true) : setUserNameLength(false);
        /^[a-zA-Z0-9-_&]*$/.test(userName) ? setUserNameSymbol(true) : setUserNameSymbol(false);
    }, [userName])

    useEffect(() => {
        if (edit) {
            const { name, email, mobile, address, city, zipcode, suspendTime, flag, products, companyName, companyLocation, optionalParameters = {}, userName, loginLimit = 0, productPermissions = [] } = userDetails;
            setName(name)
            setEmail(email);
            setContact(mobile);
            // setPassword(password)
            setAddress(address)
            setCity(city)
            setCode(zipcode)
            // setRole(role)
            // setGender(gender)
            // setMaritalStatus(maritalStatus)
            setDDate(dayjs(suspendTime))
            setProducts(products);
            setFlag(flag)
            setCompanyName(companyName)
            setCompanyLocation(companyLocation);
            setUserName(userName);
            setLoginLimit(loginLimit);
            setProductPermissions(productPermissions);

            if (Object.keys(optionalParameters).length > 0) {
                const array = Object.entries(optionalParameters).map(([key, value]) => {
                    const obj = {}
                    obj.fieldTitle = key;
                    obj.fieldValue = value;
                    return obj;
                })

                setIsOptionalParameter(true);
                setOptionalParams(array);

            }
        }

        //eslint-disable-next-line
    }, [edit])

    const handleChangeProducts = (event) => {
        const {
            target: { value },
        } = event;
        setProducts(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const inputSx = {
        color: theme.palette.text.primary,
        // borderColor: 'grey.700',
        borderRadius: "6px",
    };

    const createUser = (e) => {
        e.preventDefault();

        const [{ permission }] = productPermissions.filter(product => product.productName === "Console");

        const payload = {
            name, email, contact, password,
            address, city, code, role, permission,
            products, flag,
            dDate: +new Date(dDate), companyName,
            companyLocation, userName,
            loginLimit,
            productPermissions,
        }

        if (loginLimit < 1) {
            return renderErrorMessage("Max Concurrent Logins can not be less than zero.")
        }

        if (loginLimit > 2) {
            return renderErrorMessage("Max Concurrent Logins can not be greater than 2.")
        }

        if (isOptionalParameter) {
            const opt = {}

            optionalParams.map(({ fieldTitle, fieldValue }) => {
                if (!fieldTitle && !fieldValue) {
                    return renderErrorMessage("Optional parameters can not be empty.")
                }
                return opt[fieldTitle] = fieldValue;
            })
            payload.optionalParameters = opt;
        }

        handleCreateNewUser(payload);
    }

    const generatePassword = () => {
        const random = generateRandomString();
        setPassword(random)
    }

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '25px',
                width: '100%',
                padding: '25px'
            }}
            noValidate
            autoComplete="off"
        >

            <RowTow>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Full name *"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}

                // size='small'
                />

                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email address *"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    disabled={edit}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                // size='small'
                />
            </RowTow>

            <RowTow>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Contact number *"
                    variant="outlined"
                    type="number"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                />

                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Enter new user password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={password && (!pwdLength || !pwdNumber || !pwdUppercase || !pwdLowercase || !pwdSymbol)}
                    helperText={newPasswordError === "blank" ? "" :
                        !pwdLength ? "pasword length should be 8 to 20." :
                            !pwdNumber ? "At least 1 Number required." :
                                !pwdUppercase ? "At least 1 Uppercase character required" :
                                    !pwdLowercase ? "At least 1 Lowercase character required" :
                                        !pwdSymbol ? "At least 1 special character required" : ""
                    }
                    InputProps={{
                        sx: inputSx,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Use system generated password">
                                    <IconButton onClick={() => generatePassword()}>
                                        <Icon icon="tdesign:system-code" width="20px" />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
            </RowTow>

            <RowTow>
                <TextField fullWidth
                    id="outlined-basic"
                    label="User name"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    error={userName && (!userNameLength || !userNameSymbol)}
                    helperText={userNameError === "blank" ? "" :
                        !userNameLength ? "user name length should be 4 to 20." :
                            !userNameSymbol ? `Only -  _  &  are allowed to use in userName.` : ""

                    }
                />

                <TextField fullWidth
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />

            </RowTow>

            <RowTow>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Zip/Code"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={code}
                    onChange={e => setCode(e.target.value)}
                />

                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={city}
                    onChange={e => setCity(e.target.value)}
                />

            </RowTow>

            <RowTow>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                        <DatePicker
                            label="Choose account de-activation date"
                            fullWidth
                            value={dDate}
                            disablePast
                            minDate={tomorrow}
                            onChange={(val) => setDDate(val)}
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="simple-select-label">Apply flag</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={flag}
                        label="Permisson Role"
                        onChange={e => setFlag(e.target.value)}
                    >
                        <MenuItem value={'demo'}>Demo Account</MenuItem>
                        <MenuItem value={'temporary'}>Temporary Account</MenuItem>
                    </Select>
                </FormControl>

            </RowTow>


            <RowTow>
                <TextField
                    fullWidth
                    id="designation-basic"
                    label="Company Name"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                />

                <TextField
                    fullWidth
                    id="designation-basic"
                    label="Company Location"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={companyLocation}
                    onChange={e => setCompanyLocation(e.target.value)}
                />
            </RowTow>

            <RowTow>
                <TextField
                    fullWidth
                    id="designation-basic"
                    label="Designation"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={role}
                    onChange={e => setRole(e.target.value)}
                />
                <RowItem>
                    <TextField
                        fullWidth
                        id="loginLimit-basic"
                        label="Max Concurrent Logins"
                        variant="outlined"
                        type='number'
                        InputProps={{
                            sx: inputSx,
                        }}
                        value={loginLimit}
                        onChange={e => setLoginLimit(e.target.value)}
                        min={0}
                        max={2}
                    />
                    <Tooltip
                        PopperProps={{ style: { zIndex: 9999 } }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: 'grey.500',
                                },
                            },
                        }}
                        title={"Set the maximum number of devices this user can be logged into at the same time."}
                    >
                        <IconButton sx={{ ml: '0px !important' }}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </RowItem>
            </RowTow>

            <RowTow>

                {/* <FormControl fullWidth>
                    <InputLabel id="simple-select-label">Permissons</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={permission}
                        disabled={edit && userDetails?.permission === "l4"}
                        label="Permisson Role"
                        onChange={e => setPermission(e.target.value)}
                    >
                        <MenuItem sx={{ color: 'grey.300' }} value={"l1"} >L1 (User)</MenuItem>
                        <MenuItem sx={{ color: 'grey.300' }} value={"l4"}>L4 (Admin)</MenuItem>
                    </Select>
                </FormControl> */}

                <RowItem>
                    <FormControl fullWidth>
                        <InputLabel id="multiple-chip-label">Select Products</InputLabel>
                        <Select
                            labelId="multiple-chip-label"
                            id="multiple-chip"
                            fullWidth
                            multiple
                            value={products}
                            onChange={handleChangeProducts}
                            input={<OutlinedInput id="select-multiple-chip" label="Select products" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} sx={{ color: 'grey.100' }} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {[
                                {
                                    "permission": "l4",
                                    "productName": "Console"
                                },
                                {
                                    "permission": "l4",
                                    "productName": "Meet"
                                },
                                {
                                    "permission": "l4",
                                    "productName": "Webinar"
                                },
                                {
                                    "permission": "l4",
                                    "productName": "Broadcast"
                                },
                                {
                                    "permission": "l4",
                                    "productName": "Chat"
                                }
                            ].map(({ productName: name, permission }) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    // sx={{ color: 'grey.300' }}
                                    disabled={permission !== "l4"}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip
                        PopperProps={{ style: { zIndex: 9999 } }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    bgcolor: 'grey.500',
                                },
                            },
                        }}
                        title={"Please select the product you want to assign to the user. Choose from the available options to ensure proper allocation and access."}
                    >
                        <IconButton sx={{ ml: '0px !important' }}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </RowItem>
                <RowItem></RowItem>
            </RowTow>

            <ProductPermissionTable products={products} productPermissions={productPermissions} setProductPermissions={setProductPermissions} />

            <Box sx={{ display: "flex", width: "100%" }}>

                <FormControlLabel
                    // sx={{ color: 'grey.300' }}
                    control={
                        <Checkbox
                            checked={isOptionalParameter}
                            onChange={handleChange}
                            defaultChecked
                            size='small'
                        />
                    }
                    label="Optional Params"
                />
                <Tooltip
                    PopperProps={{ style: { zIndex: 9999 } }}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                // bgcolor: 'grey.500',
                            },
                        },
                    }}
                    title={"Add any optional parameters you want to include. Use the 'Title' input for the parameter name and the 'Value' input for the parameter value. You can add multiple pairs as needed."}
                >
                    <IconButton sx={{ ml: '0px !important' }}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {isOptionalParameter &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: 'flex-start',
                    width: '100%',
                    paddingLeft: '10px',
                    mt: '-14px'
                }}>
                    {optionalParams.map((item, index) => {
                        const { fieldTitle, fieldValue } = item;

                        return (
                            <Box key={index} sx={{ display: 'flex', gap: '5px' }}>

                                <TextField
                                    // id={nestedFieldTitle}
                                    value={fieldTitle}
                                    label={"Field Title"}
                                    size='small'
                                    onChange={(e) => onChangeOptonalParams(index, "fieldTitle", e.target.value)}
                                />

                                <TextField
                                    // id={nestedFieldTitle}
                                    value={fieldValue}
                                    label={"Field Value"}
                                    size='small'
                                    onChange={(e) => onChangeOptonalParams(index, "fieldValue", e.target.value)}
                                />

                                <IconButton onClick={() => deleteOptionalParameter(index)} color="error" sx={{ width: '38px' }}>
                                    <Iconify icon="mi:delete" width={24} />
                                </IconButton>

                                <IconButton onClick={() => addOptionalParameter()} color="primary" size='small' sx={{ width: '38px', ml: '-6px' }}>
                                    <Iconify icon="gridicons:add-outline" width={24} />
                                </IconButton>
                            </Box>
                        )
                    })}

                </Box>
            }

            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                pt: '10px',
                pb: '20px'
            }}>
                <LoadingButton
                    variant='outlined'
                    onClick={(e) => createUser(e)}
                    disabled={!checkButtonAccess("createConsoleUser", "w")}
                >
                    {edit ? "Update" : "Create"}
                </LoadingButton>
            </Box>
        </Box>

    )
}

const ProductPermissionTable = ({ products, productPermissions, setProductPermissions }) => {

    useEffect(() => {
        setProductPermissions(prevPermissions => {
            // Map the existing permissions to product names
            const permissionMap = prevPermissions.reduce((map, item) => {
                map[item.productName] = item.permission;
                return map;
            }, {});

            // Update the permissions array, adding new products and retaining existing permissions
            const newPermissions = products.map(product => ({
                productName: product,
                permission: permissionMap[product] || 'l1'
            }));

            return newPermissions;
        });
        //eslint-disable-next-line
    }, [products]);

    const handlePermissionChange = (productName, newPermission) => {
        setProductPermissions(prevPermissions =>
            prevPermissions.map(item =>
                item.productName === productName
                    ? { ...item, permission: newPermission }
                    : item
            )
        );
    };

    return (
        <TableContainer component={Paper} sx={{ maxWidth: 650, margin: 'auto', marginTop: 4, backgroundColor: "grey.1000" }}>
            <Table>
                <TableHead>
                    <TableRow /*sx={{ backgroundColor: "customGrey.700" }} */>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: 16 }}>Product Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: 16 }}>Permission</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productPermissions.map(({ productName, permission }) => (
                        <TableRow key={productName}>
                            <TableCell sx={{ padding: '16px 24px', fontSize: 14 }}>{productName}</TableCell>
                            <TableCell sx={{ padding: '16px 24px', fontSize: 14 }}>
                                <RadioGroup
                                    row
                                    value={permission}
                                    onChange={(e) => handlePermissionChange(productName, e.target.value)}
                                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                                >
                                    <FormControlLabel value="l1" control={<Radio />} label="L1 (User)" />
                                    <FormControlLabel value="l4" control={<Radio />} label="L4 (Admin)" />
                                </RadioGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NewUserForm