import React, { useState } from 'react';
import { Typography, Link, Box } from '@mui/material';

const ReadMore = ({ text, initialChunkLength = 50, chunkLength = 20, width }) => {
  const [visibleLength, setVisibleLength] = useState(initialChunkLength);

  const showMore = () => {
    setVisibleLength((prevLength) => prevLength + chunkLength);
  };

  const isFullyVisible = visibleLength >= text?.length;
  
  return (
    <Box style={{  overflowWrap: 'break-word' }}>
     {text?.length > 0  && (<Typography variant='body2'>
        {text?.substring(0, visibleLength)}
        {!isFullyVisible && (
          <>
            ...{' '}
            <Link component="button" variant="body2" onClick={showMore}>
              Read More
            </Link>
          </>
        )}
        {isFullyVisible && visibleLength < text?.length && (
          <Link component="button" variant="body2" onClick={() => setVisibleLength(initialChunkLength)}>
            Read Less
          </Link>
        )}
      </Typography>)}
    </Box>
  );
};

export default ReadMore;