import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

//Common functions
import LogoSvg from '../../assets/svgs/logo';
import { getItem, removeBulkItems } from '../../lib/cookies';
import { handleLogout } from '../../services/apisCall';
import { useAuth } from '../../hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import Logger from '../../lib/Logger';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSocket } from 'src/context/SocketContext';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

const logger = new Logger("navBar")

//@material
import { AppBar, Toolbar, Button, Typography, Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, ListItem } from "@mui/material";

//material icons
// import Logout from '@mui/icons-material/Logout';
import StyleIcon from '@mui/icons-material/Style';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { checkRouteAccess } from '../../utils/common';
import AdminsSvg from 'src/assets/svgs/admins';
import KycSvg from 'src/assets/svgs/kyc';
import LeadsSvg from 'src/assets/svgs/leads';

export default function Navbar() {
    const socket = useSocket();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState();
    const [profileUrl, setProfileUrl] = useState(null);
    const openProfile = Boolean(anchorEl);
    const valid = useAuth();

    const logout = async () => {

        try {
            await handleLogout();
            removeBulkItems([{ key: "sessionToken" }, { key: "token" }, { key: "exp" }]);
            if (socket) {
                socket.disconnect();
            }
            return window.location.href = "/";
        } catch (error) {
            logger.error("navbar [logout] catchError", error);
        }
    }

    const navbar = [
        {
            title: "Leads",
            active: location?.pathname === "/leads",
            path: "/leads",
            startIcon: <LeadsSvg />
        },
        {
            title: "KYC",
            active: location?.pathname === "/kyc",
            path: "/kyc",
            startIcon: <KycSvg />
        },
        {
            title: "Admins",
            active: location?.pathname === "/users",
            path: "/users",
            startIcon: <AdminsSvg />
        },
        {
            title: "Permission",
            active: location?.pathname === "/updatepermissions",
            path: "/updatepermissions",
            startIcon: <DeveloperModeIcon />
        }
    ]

    useEffect(() => {
        try {
            if (valid) {
                const { userName = "U", profileUrl = "" } = jwtDecode(getItem("token"));
                setName(userName)
                setProfileUrl(profileUrl)
            }
        } catch (error) {
            console.log(error);
        }
    }, [valid])

    return (
        <Box sx={{
            // pl: '68px',
            flexDirection: 'flex-end',
            position: 'fixed',
            width: '100%',
            color: '#fff',
            zIndex: 2,
            boxShadow: '68px 0.2px 3px 0px rgba(145, 158, 171, 0.2)',
        }}>
            <AppBar position="static" component="nav" sx={{
                backgroundColor: '#fff',
                boxShadow: 'none'
            }}>
                <Toolbar>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '12px',
                        ml: -1.3
                    }}>
                        <LogoSvg />
                        {/* <Typography sx={{
                            fontSize: '24px',
                            fontWeight: 700,
                            // color: 'grey.800'
                            color: 'primary.main'
                        }}>
                            Sarv.com
                        </Typography> */}
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                        // gap: '20px',
                        // backgroundColor: '#EDEFF2'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            // width: '100%',
                            gap: '10px',
                            backgroundColor: '#EDEFF2',
                            padding: '6px 7px',
                            borderRadius: '30px',
                            alignItems: 'center'
                        }}>
                            {navbar.filter(({ path }) => checkRouteAccess(path)).map(({ title, active, path, icon, startIcon }) => {
                                return (
                                    <Button onClick={() => navigate(path)}
                                        key={title}
                                        size='medium'
                                        sx={{
                                            color: active ? "grey.800" : "grey.700",
                                            bgcolor: active ? "background.default" : "transparent",
                                            // height: '34px',
                                            padding: '5px 14px',
                                            borderRadius: '20px',
                                            "&:hover": {
                                                color: active ? "grey.800" : "grey.700",
                                                bgcolor: active ? "background.default" : "transparent",
                                            }
                                        }}
                                        startIcon={startIcon}
                                        endIcon={icon}>
                                        {title}
                                    </Button>
                                )
                            })}

                            {valid && <Box sx={{ mr: '3px' }}>
                                <Avatar variant="square"
                                    src={profileUrl ? profileUrl : "../../images/avatar.png"}
                                    sx={{
                                        borderRadius: '50%',
                                        alignItems: 'center',
                                        bgcolor: "primary.main",
                                        cursor: "pointer",
                                        height: '30px',
                                        width: '30px',
                                    }}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                >
                                    {name?.slice(1)[0]}
                                </Avatar>
                            </Box>}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openProfile}
                    onClose={() => setAnchorEl(null)}
                    onClick={() => setAnchorEl(null)}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            width: 175,
                            height: 'auto',
                            borderRadius: 2,
                            '& .MuiMenuItem-root': {
                                px: 2,
                                py: 1.5,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuItem
                        sx={{
                            display: 'flex',
                            gap: 1,
                            fontWeight: 400,
                            color: 'grey.700',
                            '&:hover': {
                                color: 'grey.800',
                                backgroundColor: 'grey.300',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => navigate('/profile')}
                    >
                        <AccountBoxIcon />
                        Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={{
                            display: 'flex',
                            gap: 1,
                            fontWeight: 400,
                            color: 'grey.700',
                            '&:hover': {
                                color: 'grey.800',
                                backgroundColor: 'grey.300',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={logout}
                    >
                        <LogoutIcon sx={{ color: 'red' }} />
                        Logout
                    </MenuItem>
                </Menu>
            </Box>

        </Box>
    )
}

