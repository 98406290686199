import React, { createContext, useContext, useEffect, useState } from 'react';
import socketInstance from '../services/socket';

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(socketInstance);

  useEffect(() => {
    socket.connect();

    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
    });
    
    if (Notification.permission === 'granted') {
       console.log("permission granted already")
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
    }
    socket.on('new_ticket', (data) => {
      const label = data?.newTicket?.labels[0];
      const text = `${label} has been added to Leads`
      if (Notification.permission === 'granted') {
        new Notification(`New Lead Generated.`, {
          body: text,
          icon: ''
        });
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
