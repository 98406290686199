import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomModal = ({ open, title, description = "", close, action, width = 400 }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width,
        bgcolor: '#F0F0F0',
        p: '40px 30px 40px 30px',
        '&:focus-visible': {
            outline: 'none'
        },
        borderRadius: "8px",
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    };

    return (
        <Modal
            open={open}
            // onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(22, 28, 36, 0.8)', // 'rgba(20, 22, 24, 0.90)'
                    },
                },
            }}
        >
            <Box sx={style}>
                <IconButton
                    id="modal-close-icon"
                    onClick={close}
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        justifyContent: 'right',
                        alignItems: 'right',
                        right: 15,
                        top: 10
                    }}>
                    <CloseIcon />
                </IconButton>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: 'grey.10' }}>
                    {title}
                </Typography>
                {description}

                {action}
            </Box>
        </Modal>
    )
}

export default CustomModal;
