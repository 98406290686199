import React, { useEffect, useRef, useState } from 'react';

import { IconButton, styled, Typography, Box, Button, OutlinedInput, InputAdornment, Input } from "@mui/material";
import { jwtDecode } from 'jwt-decode';
import { getItem, setItem } from '../../lib/cookies';
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomModal from 'src/components/modal/customModal';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { axiosPostCall } from 'src/services/apisCall';
import Logger from 'src/lib/Logger';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { ToastContainer } from 'react-toastify';
import AvatarModal from './AvatarModal';
import { useNavigate, useSearchParams } from 'react-router-dom';

const MainSection = styled('div')(() => ({
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: "#E7E8E8",
    padding: '40px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'row',
    gap: '14px'
}));

const ProfileSection = styled(IconButton)(() => ({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
}));

const DetailsSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px'
}));

const NameSection = styled(Typography)(() => ({
    fontSize: '22px',
    fontWeight: 500,
}));

const EmailSection = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 400,
    color: '#5B5C5D'
}));

const MobileSection = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 400,
    color: '#5B5C5D'
}));

const ProfileSectionModal = styled(IconButton)(() => ({
    position: 'relative',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    '&:hover .upload-icon': {
        opacity: 5
    }
}));

const ProfileImage = styled('img')(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
}));

const UploadIconWrapper = styled(PhotoCameraIcon)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        cursor: 'pointer'
    }
}));

const EditProfileModal = ({ open, close, mobile, url }) => {
    const logger = new Logger();
    const [profileUrl, setProfileUrl] = useState(url)
    const [phone, setPhone] = useState(mobile);
    const [file, setFile] = useState(null);
    const [edit, setEdit] = useState(true);
    const mobileRef = useRef(null);
    const fileRef = useRef(null);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const formData = new FormData;

            if (file) formData.append("profilePhoto", file);

            if (phone && phone !== mobile) formData.append("phone", phone);
            const { message, token } = await axiosPostCall("updateProfile", formData);
            setItem("token", token);


            renderSuccessMessage(message)
            setTimeout(() => {
                close();
                navigate("/profile")
                window.location.reload(false);
            }, 500)

        } catch (error) {
            logger.error("details.jsx ,handleSubmit ,updateProfile", error);
            renderErrorMessage(error.message)
        }
    }


    const fileChange = (file) => {
        setFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileUrl(reader.result)
            };
            reader.readAsDataURL(file);
        }
        else {
            setProfileUrl(url);
        }
    }

    useEffect(() => {
        setPhone(mobile)
    }, [mobile])

    useEffect(() => {
        setProfileUrl(url)
    }, [url])


    const description = (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <ProfileSectionModal disableFocusRipple disableTouchRipple disableRipple onClick={() => fileRef.current.click()}>
                    <ProfileImage src={profileUrl} alt='profileImage' />
                    <UploadIconWrapper className="upload-icon" />
                    <input
                        type="file"
                        ref={fileRef}
                        accept='image/*'
                        onChange={(e) => fileChange(e.target.files[0])}
                        hidden
                    />
                </ProfileSectionModal>
            </Box>
            <OutlinedInput
                value={phone}
                ref={mobileRef}
                disabled={!edit}
                onChange={(e) => setPhone(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setEdit(true)}
                            edge="end"
                        >
                            <EditIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Button variant="contained" disabled={(!file && (phone === mobile))} onClick={() => handleSubmit()}>
                Save
            </Button>
        </>
    )

    return (
        <CustomModal open={open} title={"Edit Profile"} description={description} close={close} />
    )
}

const Details = () => {
    const [url, setUrl] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("")

    const [openModal, setOpenModal] = useState(false);
    const [openAvatar, setOpenAvatar] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = getItem("token");
        const { profileUrl = "", userName = "", email = "", phone = "1234567890" } = jwtDecode(token);
        setUrl(profileUrl);
        setName(userName);
        setEmail(email);
        setMobile(phone);
    }, [])

    useEffect(() => {
        const editPhone = searchParams.get("editPhone");
        if (editPhone) {
            setOpenModal(true)
        }
    })

    return (
        <MainSection>
            <ToastContainer />
            <ProfileSection disableFocusRipple disableTouchRipple disableRipple onClick={() => setOpenAvatar(true)}>
                <ProfileImage src={url} alt='profileImage' />
            </ProfileSection>
            <AvatarModal open={openAvatar} close={() => setOpenAvatar(false)} url={url} />

            <DetailsSection>
                <NameSection>
                    {name}
                </NameSection>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>

                    <EmailSection>
                        {email}
                    </EmailSection>

                    <VerifiedIcon sx={{ color: '#4BB543' }} />
                </Box>
                <MobileSection>
                    {mobile}
                </MobileSection>
                <Button variant="contained" onClick={() => setOpenModal(true)} >
                    Update Profile
                </Button>

            </DetailsSection>
            <EditProfileModal open={openModal} close={() => setOpenModal(false)} mobile={mobile} url={url} />
        </MainSection>
    )
}

export default Details;