import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// import { _contacts } from 'src/_mock';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';
import { axiosGetCall } from 'src/services/apisCall';


// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const ITEM_HEIGHT = 64;


export default function KanbanContactsDialog({ assignee = [], open, onClose, handleAssignee, handleEmit }) {
  const [_contacts, setContacts] = useState([])
  const [searchContact, setSearchContact] = useState('');
  const [activeContacts, setActiveContacts] = useState([]);

  const getAgentDetails = async () => {
    try {
      const { result } = await axiosGetCall("getAgentDetails");
      setContacts(result);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAgentDetails();
  }, [])
   
  useEffect(() => {
    handleEmit({}, "activeUsers", (err, data) => {
      setActiveContacts(data)
    })
  }, [handleEmit])
  const handleSearchContacts = useCallback((event) => {
    setSearchContact(event.target.value);
  }, []);

  const dataFiltered = applyFilter({
    inputData: _contacts,
    query: searchContact,
  });

  const notFound = !dataFiltered.length && !!searchContact;

  const sortedContacts = _contacts.sort((contactA, contactB) => {
    const isContactARelated = assignee?.map((person) => person.id).includes(contactA.id);
    const isContactBRelated = assignee?.map((person) => person.id).includes(contactB.id);

    const isContactAActive = activeContacts?.map((user) => user.email).includes(contactA.id);
    const isContactBActive = activeContacts?.map((user) => user.email).includes(contactB.id);

    if (isContactARelated && !isContactBRelated) return -1;
    if (!isContactARelated && isContactBRelated) return 1;

    if (isContactAActive && !isContactBActive) return -1;
    if (!isContactAActive && isContactBActive) return 1;

    return 0;
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 0 }}>
        Contacts <Typography component="span">({_contacts.length})</Typography>
      </DialogTitle>

      <Box sx={{ px: 3, py: 2.5 }}>
        <TextField
          fullWidth
          value={searchContact}
          onChange={handleSearchContacts}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <DialogContent sx={{ p: 0 }}>
        {notFound ? (
          <SearchNotFound query={searchContact} sx={{ mt: 3, mb: 10 }} />
        ) : (
          <Scrollbar
            sx={{
              px: 2.5,
              height: ITEM_HEIGHT * 6,
              overflowY: 'scroll'
            }}
          >
            {sortedContacts.map((contact) => {
              const isAssigned = assignee.map((person) => person.id).includes(contact.id);
              const isActiveUser = activeContacts?.map((user) => user.email).includes(contact.id);              
              const showBadge = contact.id && isActiveUser === true;
              return (
                <ListItem
                  key={contact.id}
                  disableGutters
                  secondaryAction={
                    <Button
                      size="small"
                      color={isAssigned ? 'primary' : 'inherit'}
                      onClick={() => { handleAssignee(contact, isAssigned); }}
                      startIcon={
                        <Iconify
                          width={16}
                          icon={isAssigned ? 'eva:checkmark-fill' : 'mingcute:add-line'}
                          sx={{ mr: -0.5 }}
                        />
                      }
                    >
                      {isAssigned ? 'Assigned' : 'Assign'}
                    </Button>
                  }
                  sx={{ height: ITEM_HEIGHT }}
                >
                  <ListItemAvatar>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                      invisible={!showBadge}
                    >
                      <Avatar alt={contact.name} src={contact.avatarUrl} />
                    </StyledBadge>
                  </ListItemAvatar>

                  <ListItemText
                    primaryTypographyProps={{
                      typography: 'subtitle2',
                      sx: { mb: 0.25 },
                    }}
                    secondaryTypographyProps={{ typography: 'caption' }}
                    primary={contact.name}
                    secondary={contact.email}
                  />
                </ListItem>
              );
            })}
          </Scrollbar>
        )}
      </DialogContent>
    </Dialog>
  );
}

KanbanContactsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  assignee: PropTypes.array,
};

// ----------------------------------------------------------------------

function applyFilter({ inputData, query }) {
  if (query) {
    inputData = inputData.filter(
      (contact) =>
        contact.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        contact.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}
