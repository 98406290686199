import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = ({ setSelectedPanelStatus, setLoad }) => {
    const [value, setValue] = React.useState(0);
    const statusMapper = {  
        0: "",
        1: "pending",
        2: "active",
        3: "deActivate",  
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setLoad(true);
        setSelectedPanelStatus(statusMapper[newValue])
    };

    const allLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 0 ? '#2065D1' : '#637381',
            }}>
                All
            </Typography>
        </Box>
    )

    const pendingLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 1 ? '#2065D1' : '#637381',
            }}>
                Pending
            </Typography>
        </Box>
    )

    const activeLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 2 ? '#2065D1' : '#637381',
            }}>
                Active
            </Typography>
        </Box>
    )

    const deActivateLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 3 ? '#2065D1' : '#637381',
            }}>
                De-Activated
            </Typography>
        </Box>
    )

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: '#fff'
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={allLabel} {...a11yProps(0)} />
                    <Tab label={pendingLabel} {...a11yProps(1)} />
                    <Tab label={activeLabel} {...a11yProps(2)} />
                    <Tab label={deActivateLabel} {...a11yProps(3)} />
                </Tabs>
            </Box>
        </Box>
    )
}
export default CustomTabPanel;
