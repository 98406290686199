import React from 'react';
import PagesTabPanel from './pagesTabPanel';

// @mui 
import { Typography, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

const MainSection = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between'
}));

const CheckBoxSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    // gap: '5px',
    alignItems: 'center'
}));

const Heading = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 600,
    color: "#5B5C5D"
}));

const PermissionForm = ({ handleAccess, adminAccess, edit, accessArray, setAccessArray, tabPanel }) => {

    const label = { inputProps: { 'aria-label': 'Checkbox' } };

    const allCheckHandle = () => {
        const checkArray = Object.keys(accessArray).map((id) => {
            return accessArray[id]?.["r"] && accessArray[id]?.["w"]
        })
        if (checkArray.includes(false)) return false;
        return true;
    }


    const handlePermissionChange = (id, type, bool) => {
        const newObj = { ...accessArray };
        newObj[id][type] = bool;
        setAccessArray(newObj);
    }

    const handleBothChange = (id, bool) => {
        adminAccess[id]?.r ? handlePermissionChange(id, "r", bool) : ""
        adminAccess[id]?.w ? handlePermissionChange(id, "w", bool) : ""
    }

    const handleAllChange = (event) => {
        Object.keys(accessArray).map((id) => {
            handleBothChange(id, event.target.checked);
        })

    }

    return (
        <MainSection>
            <HeadingSection>
                <Heading>
                    Permissions
                </Heading>

                <CheckBoxSection>
                    <Checkbox checked={allCheckHandle()} onChange={(e) => handleAllChange(e)} {...label} defaultChecked size='small' />

                    <Typography sx={{ fontSize: '14px' }}>
                        Check here to allow all pages access
                    </Typography>
                </CheckBoxSection>

            </HeadingSection>


            <PagesTabPanel handleAccess={handleAccess} edit={edit} accessArray={accessArray} setAccessArray={setAccessArray} tabPanel={tabPanel} adminAccess={adminAccess} />
        </MainSection>
    )
}

export default PermissionForm;