import React, { useEffect, useState, input } from 'react'
import { checkUserRole } from '../../utils/common';

import { TextField, InputLabel, FormControl, Select, MenuItem, Button, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
const FormSection = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    gap: '8px',
    height: '40px'

}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#D0D0D1",
    borderRadius: '6px',
    '& input': {
        color: "#5B5C5D",
    },
    width: '350px',
    height: 'auto'
}));

const BasicSelect = styled(Select)(({ theme }) => ({
    backgroundColor: "#D0D0D1",
    borderColor: "#5B5C5D",
    borderRadius: '6px',
    '& input': {
        color: "#5B5C5D",
    },
    width: '350px',
}));

const SelectPage = ({ tabPanel, selectedPanel, setSelectedPanel, handleAddPage }) => {
    const [selectDisable, setSelectDisable] = useState(false);

    const handleSelect = (event) => {
        setUserType(event.target.value);
    };

    useEffect(() => {
        if (checkUserRole() === "admin") {
            if (edit) {
                setSelectDisable(true)
            }
            else {
                setUserType("agent")
                setSelectDisable(true)
            }
        }
    })


    const handleChange = (event) => {
        setSelectedPanel(event.target.value);
    };

    return (
        <FormSection>
            <FormControl sx={{ width: 150 }}>
                <InputLabel id="demo-simple-select-label">Page</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPanel}
                    label="Page"
                    onChange={handleChange}
                    width={350}
                >
                    {Object.keys(tabPanel).map((id) => (
                        <MenuItem value={id}>{tabPanel[id].title}</MenuItem>
                    ))
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}

                </Select>
            </FormControl>

            <LoadingButton
                variant='contained'
                sx={{ boxShadow: 'none' }}

                onClick={handleAddPage}
            // loading={addUserLoader}
            >
                {"Add Page"}
            </LoadingButton>
        </FormSection>
    )
}

export default SelectPage