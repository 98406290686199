import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AddUserSvg from '../assets/svgs/addUser';

import PermissionForm from '../sections/users/permissionForm';
import pageTitle from "../utils/pageTitles.json";
import { PermissionContext } from '../context/PermissionContext';
import DetailsForm from '../sections/users/detailsForm';
import { axiosGetCall, axiosPostCall } from '../services/apisCall';

import { renderErrorMessage, renderSuccessMessage } from '../lib/toastMessage';
import { ToastContainer } from 'react-toastify';

const logger = new Logger("kycListTable");
import Logger from '../lib/Logger';

//@mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import SelectPage from 'src/sections/panelPermission/detailsForm';
import PagePermissions from 'src/sections/panelPermission/permission';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

const MainSection = styled('div')(() => ({
  width: '100%',
  padding: '64px 20px 0px 88px',
  backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
  height: '100vh'
}));

const MainSectionInner = styled('div')(() => ({
  backgroundColor: "#fff",
  padding: '30px 20px',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  height: `calc(${window.innerHeight}px - 157px)`
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
  width: '100%',
  padding: "20px 0px 20px 0px",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row'
}));

const PageInput = ({ pageDialog, addNewPage }) => {
  const [newPageName, setNewPageName] = useState("");

  const handleConfirm = () => {
    addNewPage(newPageName)
    pageDialog.onFalse()

  }
  const NewPageInputContent = (

    <TextField
      id="outlined-basic"
      label="Enter PAge NAme"
      variant="outlined"
      value={newPageName}
      onChange={(e) => setNewPageName(e.target.value)}
      fullWidth
    />
  )

  const newPageDialogAction = () => {
    return (
      <Button onClick={handleConfirm}>
        Confirm
      </Button>
    )
  }
  return (

    <ConfirmDialog title="Enter new page" content={NewPageInputContent} open={pageDialog.value} onClose={() => pageDialog.onFalse()} action={newPageDialogAction()} />
  )
}

const UpdatePanelPermission = () => {

  const pageDialog = useBoolean();
  const [tabPanel, setTabPanel] = useState({})
  const [tabs, setTabs] = useState({})
  const [selectedPanel, setSelectedPanel] = useState(null);

  const handleTabDelete = (tab) => {
    const { id, parent } = tab;
    const newTabPanel = JSON.parse(JSON.stringify(tabPanel?.[parent]))
    const index = newTabPanel.child.indexOf(id);
    if (index > -1) {
      newTabPanel.child.splice(index, 1);
    }

    setTabs((prev) => {
      const updatedTabs = { ...prev };
      delete updatedTabs?.[id];
      return updatedTabs;
    })

    setTabPanel((tabPanel) => {
      const obj = { ...tabPanel }
      obj[parent] = newTabPanel
      return obj;
    })
  }

  const handleTabAdd = (parentObj) => {
    const { id, child } = parentObj;

    const newTabPanel = JSON.parse(JSON.stringify(parentObj))

    if (child.length > 0) {
      const childId = parseInt(child[child.length - 1]) + 1;
      const newTab = {
        parent: id,
        r: [],
        w: [],
        id: childId,
        title: '',
        type: 'child',
      }

      setTabs((tabs) => {
        const obj = { ...tabs };
        obj[childId] = newTab
        return obj;
      })

      newTabPanel.child.push(childId);
      setTabPanel((tabPanel) => {
        const obj = { ...tabPanel }
        obj[id] = newTabPanel
        return obj;
      })


    }
    else {
      const childId = parseInt(id) * 1000 + 1;
      const newTab = {
        parent: id,
        r: [],
        w: [],
        id: childId,
        title: '',
        type: 'child',
      }
      setTabs((tabs) => {
        const obj = { ...tabs };
        obj[childId] = newTab
        return obj;
      })

      newTabPanel.child.push(childId);
      setTabPanel((tabPanel) => {
        const obj = { ...tabPanel }
        obj[id] = newTabPanel
        return obj;
      })

    }

  }

  const updateTabRoute = (id, type, value) => {
    setTabs((prev) => {
      const updatedTab = { ...prev };
      updatedTab[id][type] = value;
      return updatedTab;
    })

  }

  const deletePage = (id) => {
    setTabPanel((prev) => {
      const newTabPanel = { ...prev };
      const children = newTabPanel[id].child;

      if (children.length > 0) {

        setTabs((prev) => {
          const newTabs = { ...prev };
          for (let i = 0; i < children.length; i++) {
            const element = children[i];
            delete newTabs?.[element];
          }
          return newTabs;
        })

      }
      delete newTabPanel?.[id];
      return newTabPanel;
    })
    setSelectedPanel(null);
  }

  const handleAddPage = () => {

    pageDialog.onTrue();

  }


  const addNewPage = (newPageName) => {
    const array = Object.keys(tabPanel).map((id) => parseInt(id));
    const latestId = Math.max(...array)
    setTabPanel((tabPanel) => {
      const newTabPanel = { ...tabPanel };
      const newPage = {
        id: latestId + 1,
        title: newPageName,
        type: 'parent',
        child: [],

      }
      newTabPanel[latestId + 1] = newPage;
      return newTabPanel;
    })
  }

  const updatePageTitle = (tabPanelId, value) => {
    const { id } = tabPanelId;

    setTabPanel((tabPanel) => {
      const newTabPanel = { ...tabPanel };
      newTabPanel[id].title = value;
      return newTabPanel;
    })

  }

  const updateTabName = (tab, value) => {
    const { id, parent } = tab;

    setTabs((tab) => {
      const newTab = { ...tab };
      newTab[id].title = value;
      return newTab;

    })
  }

  const updatePermission = async () => {

    try {
      const { message } = await axiosPostCall("updatePanelPermission", { tabs: Object.values(tabs), tabPanel: Object.values(tabPanel) })
      return renderSuccessMessage(message)

    } catch (error) {
      renderErrorMessage(error?.message)
      console.error(error)
    }

  }

  const getPanelPermission = async () => {
    try {

      const { tabs, tabPanel } = await axiosGetCall("getPanelPermission");
      setTabs(tabs)
      setTabPanel(tabPanel)
    } catch (error) {
      renderErrorMessage(error?.message)
      console.error(error)
    }
  }

  useEffect(() => {
    getPanelPermission()
  }, [])

  return (
    <>
      <Helmet>
        <title> {`${pageTitle?.addNewUser}`} </title>
      </Helmet>
      <ToastContainer />
      <MainSection>

        <HeadingSection>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{
              widht: '16px',
              height: '16px',
              mt: -1.8
            }}>
              <AddUserSvg />
            </Box>
            <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
              Update Panel Permissions
            </Typography>
          </Box>
        </HeadingSection>

        <MainSectionInner>
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="warning">Developer Notice: This page is restricted to developers only. Please do not make any unauthorized changes to the permission flow.</Alert>
          </Stack>
          <SelectPage tabPanel={tabPanel} selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel} handleAddPage={handleAddPage} />

          {selectedPanel && <PagePermissions tabsIds={tabPanel?.[selectedPanel] || {}} tabs={tabs} handleTabDelete={handleTabDelete} handleTabAdd={handleTabAdd} updateTabName={updateTabName} updatePageTitle={updatePageTitle} updateTabRoute={updateTabRoute} deletePage={deletePage} />}
          <PageInput pageDialog={pageDialog} addNewPage={addNewPage} />
          <Button
            variant='contained'
            color='success'
            sx={{ width: "30px" }}
            onClick={updatePermission}
            disabled={!selectedPanel}
          >Update</Button>
        </MainSectionInner>
      </MainSection >
    </>
  )
}

export default UpdatePanelPermission;