import { TextField, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const SearchTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[700],
    borderRadius: '6px',
    '& input': {
        color: theme.palette.grey[500],
    },
    width: '100%',
}));

const SearchButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
    }
}))

const MainSection = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '20px',
    alignItems: 'center'
})

const FilterSection = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center'
})

const Count = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#434546',
})

const TableFilters = ({ search, handleChange, setLoad, count, setPage }) => {
    const handleSearch = () => {
        setPage(0);
        setLoad(true);
    }

    return (
        <MainSection>
            <FilterSection>
                <SearchTextField
                    type="text"
                    id="searchEmailId"
                    onChange={(e) => handleChange(e.target.value)}
                    value={search}
                    size='small'
                    placeholder="Search by email"
                    variant="outlined"
                />
                <SearchButton
                    variant='contained'
                    size='medium'
                    onClick={() => handleSearch()}
                >
                    Search
                </SearchButton>
            </FilterSection>

            <Count>
                Total Users: {count}
            </Count>
        </MainSection>
    )

}

export default TableFilters;