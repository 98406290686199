import React from 'react';
import { Menu } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        PaperProps={{
            elevation: 0,
            sx: {
                ml: 1,
                mt: -1,
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.32))',
                // '&:before': {
                //     content: '""',
                //     display: 'block',
                //     position: 'absolute',
                //     top: 0,
                //     right: 14,
                //     width: 12,
                //     height: 12,
                //     backgroundColor: '#fff',
                //     transform: 'translateY(-50%) rotate(45deg)',
                //     zIndex: 0,
                // },
            },
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 10,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        backgroundColor: 'grey.200',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const CustomMenu = ({ anchorEl, handleClose, Content }) => {
    return (
        <StyledMenu
            id="filters-menu"
            MenuListProps={{
                'aria-labelledby': 'filters-menu',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <Content />
        </StyledMenu>
    )
}
