import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
};

const ProfileImage = styled('img')(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
}));

const ProfileSection = styled(IconButton)(() => ({
    width: '500px',
    height: '500px',
    borderRadius: '50%',
    overflow: 'hidden',
}));

export default function AvatarModal({ open, close, url }) {
    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={close}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                    sx: {
                        backgroundColor: 'rgba(22, 28, 36, 0.8)', // 'rgba(20, 22, 24, 0.90)'
                    },
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <ProfileSection>

                        <ProfileImage src={url} />
                    </ProfileSection>

                </Box>
            </Fade>
        </Modal>

    );
}