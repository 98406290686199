import React from 'react'

const KycTitleIcon = () => (
    <svg width="30" height="30" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M389.408 335.583L304 311.863H256H208L122.592 335.591C87.96 345.207 64 376.735 64 412.671V495.999H256H448V412.671C448 376.735 424.04 345.207 389.408 335.583Z" fill="#3573F0" />
        <path d="M128 448H144V496H128V448Z" fill="#2E55A3" />
        <path d="M304.001 311.863H256.001H208.001L192.217 316.247C194.417 349.551 222.145 375.999 256.001 375.999C289.857 375.999 317.585 349.551 319.785 316.247L304.001 311.863Z" fill="#2E55A3" />
        <path d="M304 255.863H208V311.863L208.008 311.871C208.008 311.911 208 311.951 208 311.999C208 338.511 229.488 359.999 256 359.999C282.512 359.999 304 338.511 304 311.999C304 311.951 303.992 311.911 303.992 311.863L304 255.863Z" fill="#FCB98B" />
        <path d="M352 111.863V191.999C352 245.015 309.016 287.999 256 287.999C202.984 287.999 160 245.015 160 191.999V111.863H352Z" fill="#FFD3B3" />
        <path d="M352 136H357.544C367.28 136 374.76 144.624 373.384 154.272L369.968 178.136C368.84 186.016 362.088 191.864 354.128 191.864H352V136Z" fill="#FFD3B3" />
        <path d="M160.001 136H154.457C144.721 136 137.241 144.624 138.617 154.272L142.033 178.136C143.161 186.016 149.913 191.864 157.873 191.864H160.001V136Z" fill="#FFD3B3" />
        <path d="M352 191.864L336 127.864H256H176L160 191.864V96C160 51.816 195.816 16 240 16H280C297.672 16 312 30.328 312 48C334.088 48 352 65.912 352 88V191.864Z" fill="#27282E" />
        <path d="M424 80H408V24H352V8H424V80Z" fill="#DB3B4B" />
        <path d="M104 80H88V8H160V24H104V80Z" fill="#DB3B4B" />
        <path d="M424 312H352V296H408V240H424V312Z" fill="#DB3B4B" />
        <path d="M160 312H88V240H104V296H160V312Z" fill="#DB3B4B" />
        <path d="M88 152H424V168H88V152Z" fill="#DB3B4B" />
        <path d="M416 496C460.183 496 496 460.183 496 416C496 371.817 460.183 336 416 336C371.817 336 336 371.817 336 416C336 460.183 371.817 496 416 496Z" fill="#208A3C" />
        <path d="M416 480C451.346 480 480 451.346 480 416C480 380.654 451.346 352 416 352C380.654 352 352 380.654 352 416C352 451.346 380.654 480 416 480Z" fill="#EDEFF1" />
        <path d="M408 447.312L378.344 417.656L389.656 406.344L408 424.688L448.344 384.344L459.656 395.656L408 447.312Z" fill="#208A3C" />
    </svg>
)
export default KycTitleIcon;
