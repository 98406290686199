// All apis base url
export function getApiUrl() {
  return "http://localhost:4001/";
}

// Get api endpoints dynamically
export function endPoints(title) {
  const version = 'v1/';
  const routes = {
    login: `${version}auth/login`,
    logout: `${version}auth/logout`,
    fetchKycUserList: `${version}kyc/fetchKycUserList`,
    getUserKycDoc: `${version}kyc/getUserKycDoc`,
    updateKycDocStatus: `${version}kyc/updateKycDocStatus`,
    updateKycStatus: `${version}kyc/updateKycStatus`,
    sendRejectedMail: `${version}kyc/sendRejectedMail`,
    updateKycStatus: `${version}kyc/updateKycStatus`,
    consoleUsersList: `${version}users/consoleUsersList`,
    adminUsersList: `${version}admin/adminUsersList`,
    createAdminUser: `${version}admin/createUser`,
    advertisement: `${version}advertisment/upload`,
    consoleReferenceLogin: `${version}mis/consoleReferenceLogin`,
    getUserAccess: `${version}admin/getUserAccess`,
    updateUserDetails: `${version}admin/updateUserDetails`,
    updateUserStatus: `${version}admin/updateUserStatus`,
    getAgentDetails: `${version}crm/getAgentsDetails`,
    forceLogout: `${version}admin/forceLogout`,
    getCrmActivityLogs: `${version}crm/getCrmActivityLogs`,
    updateProfile: `${version}admin/updateProfile`,
    updateForceVerifyPermission: `${version}users/updateForceVerifyPermission`,
    getUserPassword: `${version}users/getUserPassword`,
    getAllAdmin: `${version}admin/getAllAdmin`,
    updateUserAdmin: `${version}users/updateUserAdmin`,
    updatePanelPermission: `${version}admin/updatePanelPermission`,
    getPanelPermission: `${version}admin/getPanelPermission`,
    createConsoleUser: `${version}admin/createConsoleUser`,
    fetchToken: `${version}auth/fetchToken`,
  };

  const baseUrl = getApiUrl();
  const routeEndPoint = Object.entries(routes).find((item) => item[0] === title);
  return `${baseUrl}${routeEndPoint[1]}`;
}

export const GOOGLE_AUTH_CLIENT_ID = "953073211267-0fgof26874r31npfsdfrjiku23pngarm.apps.googleusercontent.com";

export const URL_MAPPER = {
  // console: 'http://localhost:3000'
  console: "https://dev-console.sarv.com"
}

export const CALL_CLIENT_URL = `https://s-ct3.sarv.com/v2/clickToCall/para?&user_id=87164080&token=KvwX5mDwj2i71cJ7N1q1&from=0000000000&fromType=Number&to=0000000000`